// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Avatar, Stack, Tabs, Tab,
  Paper,
  Card,
  CardMedia,
  CardContent,
  Tooltip,
  Rating,
  LinearProgress,
} from "@mui/material";

import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import {searchIcon,foodImg, profileImg } from "./assets";
import { service } from "./assets";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const baseURL = require("../../../framework/src/config.js");
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import PortfolioManagementController, {
  Props,
  IListItem,
  reviews,
  ratingsData,
} from "./PortfolioManagementController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class PortfolioManagement extends PortfolioManagementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {searchCategory} = this.state
    const ratingStar = [5, 4, 3, 2, 1]
    return (
      <> 
        <Box sx={webStyle.mainWrapper}>
           <Container maxWidth={false} sx={{ maxWidth: "1300px", mx: "auto", p: 2, my:2, borderRadius:'16px', background:"#fff" }}>
               <Box sx={{display:'flex', gap:'24px'}}>
                <Avatar
                    src={this.state.portfolioData[0]?.attributes.account?.profile_picture_url||''}
                    alt="Portfolio Image"
                    sx={{ width: 136, height: 136, borderRadius: "50%" }}
                  />
              <Stack direction="column" spacing={8} alignItems="left">
                <Typography variant="h4" style={{
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  fontSize: '24px'
                }}>{this.state.portfolioData[0]?.attributes?.account?.first_name||''}</Typography>
                <Box style={{margin:"10px 0"}}>
                <Typography variant="caption"
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#62646A',
                    fontFamily:'poppins',
                    margin: '0'
                  }}> Chef Trainer</Typography>
                <Box display="flex" flexDirection="row" gap={1} style={{marginTop:0}} >
                  <Box style={{display:"flex",  alignItems:"center"}}>
                    <Rating
                      name="customized-10" defaultValue={2} max={1}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          height: "15px", width: "15px", color: "#FFB33E"
                        },
                      }}
                      />
                      <Rating
                      sx={{
                        "& .MuiSvgIcon-root": {
                          height: "15px", width: "15px", color: "#FFB33E"
                        },
                      }}
                      name="customized-10" defaultValue={2} max={1} />
                      <Rating
                      sx={{
                        "& .MuiSvgIcon-root": {
                          height: "15px", width: "15px", color: "#FFB33E"
                        },
                      }}
                      name="customized-10" defaultValue={2} max={1} 
                      />
                      <Rating
                      name="customized-10" defaultValue={2} max={1}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#FFB33E", height: "15px", width: "15px"
                        },
                      }}
                       />
                      <Rating
                      sx={{
                        "& .MuiSvgIcon-root": {
                          width: "15px", height: "15px", color: "#FFB33E"
                        },
                      }}
                      name="customized-10" defaultValue={2} max={1} />
                  </Box>
                  <Box marginRight="12px">
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "13px", color: "#FFB33E" }}>
                      4.8
                    </Typography>
                  </Box>
                  <Box>
                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "13px", color: "#62646A" }}>
                      (974)
                    </Typography>
                  </Box>
                </Box>
                </Box>
                <Button variant="contained" color="primary" style={{ 
                  backgroundColor: "#44041C",
                  margin: 0,
                  width:'122px',
                  fontSize:'14px',
                  textTransform:'capitalize',
                  color:'#fff',
                  fontWeight:600,
                  fontFamily:'Poppins',
                  boxShadow:'none'
                }}>
                  Contact
                </Button>
              </Stack>
               </Box>

              
      <Paper square style={{boxShadow:"none"}}>
        <Tabs
          value={this.state.tabvalue}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}
          aria-label="portfolio management tabs"
          data-test-id="handleChange"
          sx={{
            borderBottom: "1px solid #E2E8F0", 
            marginTop: "30px", 
            "& .MuiTab-root": { color: "#64748B", textTransform: "capitalize", fontSize:"18px",
              fontFamily:"Poppins" },  
            "& .MuiTab-root.Mui-selected": { color: "#44041C", fontWeight: "bold", fontSize:"18px",
              fontFamily:"Poppins", borderBottom: "1px solid #44041C", },
            "& .MuiTabs-indicator": {backgroundColor:'#44041C'}
          }}
        >
          <Tab label="Services" />
          <Tab label="Reviews" />
        </Tabs>
        <Box>
          {this.state.tabvalue === 0 && <div style={{padding:'30px 16px'}}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "30px", color: "#101828" , marginBottom:'30px' }}>
              Browse All Services
            </Typography>
            <TextField
              data-test-id="search"
              variant="outlined"
              placeholder="Search"
              value={searchCategory}
              onChange={(e) => {
                const value = e.target.value;
                this.setState({ searchCategory: value }, () => {
                    this.searchCategoryCards();
                    
                });
              }} 
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); 
                  this.searchCategoryCards(); 
                }
              }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#667085",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "Poppins",
                  paddingLeft: "10px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  fontFamily: "Poppins",
                  width: "320px",
                  height: "44px",
                  backgroundColor: "#fff",
                  "&:hover fieldset": {
                    border: "1px solid #D6D3D1",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #D6D3D1",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <img src={searchIcon} alt="searchIcon" style={{
                    height: "20px", width: "20px", color: "#667085"
                  }} />
                ),
              }}
            />
          </Box>


          <Grid container spacing={2}>
            {this.state.serviceData?.length == 0  || this.state.serviceData === null ? (
              <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "50vh", marginInline: "auto",
                marginTop: "80px"
              }}
            >
              <Box mb={2}>
                <img src={service} alt="No Service" style={{ maxHeight: "200px" }} />
              </Box>
              <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "22px", color: "#4A4A4A" }}>
                No services yet
              </Typography>
              <Typography sx={{ textAlign: "center", fontWeight: 400, fontFamily: "Poppins", fontSize: "18px", color: "#4A4A4A", marginTop: 2 }}>
                Please come back later, Lorem Ipsum
              </Typography>
              <Typography sx={{ textAlign: "center", fontWeight: 400, fontFamily: "Poppins", fontSize: "18px", color: "#4A4A4A" }}>
                Domet Amet sillus Apet.
              </Typography>
            </Box>): this.state.serviceData && Array.isArray(this.state.serviceData) && this.state.serviceData.length > 0 && (
               <>
               {this.state.serviceData?.map((portfolioItem:any)=>(
                <Grid item xs={12} sm={6} md={4}>
                  <Card sx={{ boxShadow: "0px 4px 10px rgb(189 179 179 / 40%)", borderRadius: "16px", height: "461px", display: "flex", flexDirection: "column" }}>
                    <CardMedia
                      component="img"
                      height="172px"
                      image = {portfolioItem.attributes?.listing_images && portfolioItem?.attributes?.listing_images[0]?.url}
                      alt="image"
                    />
                    <CardContent>
                      <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "60.33px", borderRadius: "17px", fontFamily: "Poppins", fontWeight: 700, fontSize: "10px", color: "#404145", backgroundColor: "#EFEFF0" }}>
                        Service
                      </Typography>
                      <Box height="163px">
                        <Tooltip title={'service?.title'} arrow>
                          <Typography sx={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word", marginTop: "13px", fontFamily: "Poppins", fontWeight: 700, fontSize: "17px", color: "#404145"
                          }}>
                           {portfolioItem.attributes.sub_category.name}
    
                          </Typography>
                        </Tooltip>
                        <Box display="flex" alignItems="center" flexDirection="row">
                          <Box style={{}}>
                            <Rating
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  height: "12px", width: "13px", color: "#6D8E55"
                                },
                              }}
                              name="customized-10" defaultValue={2} max={1} />
                          </Box>
                          <Box marginRight="12px">
                            <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: "#6D8E55" }}>
                              4.8
                            </Typography>
                          </Box>
                          <Box marginRight="12px">
                            <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "13px", color: "#B5B6BA" }}>
                              (227)
                            </Typography>
                          </Box>
                          <Box>
                            <QueryBuilderIcon style={{ height: "13px", width: "12px" }} /> <span style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: "14px", color: "#62646A" }}> {portfolioItem.attributes.duration} hours</span>
                          </Box>
                        </Box>
                        <Box> 
                           <Typography style={{fontSize:'14px', fontFamily:'Poppins', fontWeight:400, color:'#222325'}}> by {portfolioItem.attributes.created_by}</Typography>
                        </Box>
                        <Box>
                        <Typography sx={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            overflow: "hidden",
                            wordBreak: "break-word",
                            textOverflow: "ellipsis", fontFamily: "Poppins", fontWeight: 400, fontSize: "12px", color: "#95979D"
                          }}>
                          {portfolioItem.attributes.description}
                          </Typography>
                          <Typography sx={{ marginTop:"15px",fontFamily:"Poppins",fontWeight:400,fontSize:"15px",color:"#404145" }}>
                            $ {portfolioItem.attributes.price}
                          </Typography>
                         
                        </Box>
                      </Box>
                      <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                              textTransform: "none", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#44041C", marginTop: "32.42px", borderRadius: "8px", border: "1px solid #44041C",
                              "&:hover": {
                                border: "1px solid #44041C"
                              },
                              "&:focus": {
                                border: "1px solid #44041C"
                              },
                              "&:active": {
                                border: "1px solid #44041C"
                              },
                            }}
                          >
                            Continue
                      </Button>
                    </CardContent>
                  </Card>  
                </Grid>
               ))}
               </>
            )}
              
            
         
          </Grid>
          
          </div>}
          {this.state.tabvalue === 1 && <div style={{padding:'30px 16px'}}>
            <Box>
              <Typography  fontWeight="bold" style={{fontFamily:"Poppins", fontSize:'30px', marginBottom:'30px'}}>
                  Browse All Reviews
              </Typography>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Box display="flex" alignItems="center" gap={3}>
                    <Typography style={{fontFamily:"Poppins", fontSize:'60px', fontWeight:700}} > 4.3 </Typography>
                    <Typography style={{fontFamily:"Poppins", fontSize:'24x', fontWeight:400, color:'#57534E'}}>48 Reviews</Typography>
                  </Box>
                  {ratingsData.map((rating) => (
                    <Box key={rating.stars} display="flex" alignItems="center" gap={1} my={1}>
                      <Typography fontWeight="bold">{rating.stars}</Typography>
                      <StarIcon sx={{ color: "#FFC107" }} />
                      <LinearProgress
                        variant="determinate"
                        value={rating.percentage}
                        sx={{
                          flexGrow: 1,
                          height: 10,
                          borderRadius: 5,
                          backgroundColor: "#E0E0E0",
                          "& .MuiLinearProgress-bar": { backgroundColor: "#FFC107" },
                        }}
                      />
                      <Typography>{rating.count}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
              <Box flex={1}>
                <Box display="flex" gap={1} mb={6}>
                    {ratingStar.map((star) => (
                      <Button
                        key={star}
                        variant={this.state.selectedRating === star ? "contained" : "outlined"}
                        sx={{
                          borderRadius: "20px",
                          
                          fontWeight: 700,
                          fontSize:'18px',
                          borderColor: '#A8A29E',
                          bgcolor: this.state.selectedRating === star ? "#6B0F1A" : "white",
                          color: this.state.selectedRating === star ? "white" : "black",
                          "&:hover": { bgcolor: "#6B0F1A", color: "white" },
                        }}
                        onClick={() => this.handleFilterClick(star)}
                        data-test-id="handleFilters"
                      >
                        {star} <StarIcon sx={{ fontSize: 24, color: "#FFC107", ml: 0.5 }} />
                      </Button>
                    ))}
                  </Box>
                  <Box>
                  {reviews
                  .filter((review) => !this.state.selectedRating || review.rating === this.state.selectedRating)
                  .map((review, index) => (
                    <Card key={index} sx={{ mb: 2, borderRadius: 3, border:'1px solid #D6D3D1' }}>
                      <CardContent>
                        <Box style={{display:"flex", justifyContent:"space-between", alignItems:'center' , marginBottom:'16px'}}>
                          <Box display="flex" alignItems="center" gap={2}>
                            <Avatar src={review.avatar} />
                            <Typography style={{fontSize:'23px', fontFamily:"Poppins", fontWeight:400 , color:'#323232'}}>{review.name} • {review.date}</Typography>
                          </Box>
                          <Rating value={review.rating} readOnly icon={<StarIcon fontSize="large" />} emptyIcon={<StarBorderIcon fontSize="large" />} />
                        </Box>
                        <Typography style={{fontSize:'23px', fontFamily:"Poppins", fontWeight:400 , color:'#323232'}}>{review.comment}</Typography>
                      </CardContent>
                    </Card>
                  ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>}
        </Box>
      </Paper>
    </Container>
</Box>
      </>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Poppins",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    paddingBottom: "30px",
    background: "#69616112",
  },
  video: {
    height: 200,
    width: 350,
    padding: 20,
    borderRadius: 25,
  },

  elementWrap: {
    backgroundColor: "lightgrey",
  },

  searchfield: {
    backgroundColor: "lightgrey",
    borderRadius: 5,
    padding: 5,
  },

  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  imageGrid: {
    backgroundColor: "lightgrey",
    padding: 20,
    marginTop: 50,
    borderRadius: 10,
  },
  imageAll: {
    height: 200,
    width: 200,
    margin: "auto",
    padding: 20,
    borerRadius: 20,
    backgroundColor: "red",
  },
  docOuterGrid: {
    backgroundColor: "lightgrey",
    padding: 20,
    marginTop: 30,
    borerRadius: 15,
  },
  docGrid: {
    padding: 10,
    margin: 10,
  },
  videoOuter: {
    backgroundColor: "lightgrey",
    padding: 20,
    marginTop: 30,
    borerRadius: 20,
  },
};

// Customizable Area End
