import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

// Customizable Area Start
import React from "react";
export const configJSON = require("./config");
import {getStorageData} from '../../../framework/src/Utilities'
import { toast } from 'react-toastify';
interface UserProfile {
  id: string;
  type: string;
  attributes: {
    personal_profile: {
      full_name: string;
      email: string;
      bio: string | null;
      city: string | null;
      state: string | null;
      country: string | null;
      address: string | null;
      postal_code: string | null;
      account_id: number;
    };
    profile_picture: string | null;
    social_links: {
      linkedin_url: string | null;
      facebook_url: string | null;
      instagram_url: string | null;
      pinterest_url: string | null;
      x_url: string | null;
    };
  };
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedImage:any;
  profileData:UserProfile,
  passwordChangePopup:boolean,
  newPwd:string;
  showConfirmPwd:boolean;
  password:string,
  showPwd:boolean,
  confirmNewPassword:string,
  showConfirmNewPwd:boolean,
  accountDeletePopup:boolean,
  token:string,
  changePasswordMsg:string,
  changePwdError:string,
  xurl:string;
  pinterestUrl:string;
  instagramUrl:string;
  linkedinUrl:string;
  facebookUrl:string;
  bio:string;
  location:string;
  email:string;
  fullName:string;
  loginId:null | string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SocialMediaAccountRegistrationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fileInputRef: any;
  deleteAccountApiCallId?: string;
  changePwdApiCallId?: string;
  profileUpdateApiCallId?:string;
  getProfileApiCallId?:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    // Customizable Area End
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      profileData:{
        id: "",
        type: "",
        attributes: {
          personal_profile: {
            full_name: "",
            email: "",
            bio: "",
            city: "",
            state: "",
            country: "",
            address: "",
            postal_code: null,
            account_id: 0
          },
          profile_picture: null,
          social_links: {
            linkedin_url: "",
            facebook_url: "",
            instagram_url: "",
            pinterest_url: "",
            x_url: ""
          }
        }
      },
      xurl:"",
      pinterestUrl:"",
      instagramUrl:"",
      linkedinUrl:"",
      facebookUrl:"",
      bio:"",
      location:"",
      email:"",
      fullName:"",
      selectedImage:"",
      passwordChangePopup:false,
      newPwd:"",
      showConfirmPwd:false,
      password:"",
      showPwd:false,
      showConfirmNewPwd:false,
      confirmNewPassword:"",
      accountDeletePopup:false,
      token:"",
      changePasswordMsg:"",
      changePwdError:"",
      loginId:null,
      // Customizable Area End
    };

    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ]);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (apiRequestCallId === this.changePwdApiCallId) {
      if(responseJson?.message){
        this.setState({changePasswordMsg : responseJson?.message})
        toast.success(responseJson?.message);
        this.setState({passwordChangePopup : false})
      }
      else if (responseJson?.error) {
        toast.error(responseJson.error); 
      }
     }
     if(apiRequestCallId === this.getProfileApiCallId){
      this.setState({profileData : responseJson?.data,
        bio:responseJson?.data?.attributes?.personal_profile?.bio,
        fullName:responseJson?.data?.attributes?.personal_profile?.full_name,
        location:responseJson?.data?.attributes?.personal_profile?.city,
        email :responseJson?.data?.attributes?.personal_profile?.email
      })
    }
     if(apiRequestCallId === this.profileUpdateApiCallId){
      if(responseJson?.data){
      this.getProfileData()
      toast.success("User Profile Update Successfully");
    }
      else if (responseJson?.errors) {
        const firstErrorKey = Object.keys(responseJson.errors[0])[0]; 
        const firstErrorMessage = responseJson.errors[0][firstErrorKey]; 
        toast.error(firstErrorMessage);
      }
     }
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount() {
    const loginApiToken = await getStorageData("loginToken")
    this.setState({token:loginApiToken})
    const loginId = await getStorageData("loginID")
    this.setState({loginId:loginId})
    this.getProfileData()
  }
  handleLogout = () => {
    localStorage.removeItem("loginToken"); 
    this.props.navigation.navigate("EmailAccountLoginBlock")
  };
  
  handleImageChange = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      this.setState({ selectedImage: imageUrl });
    }
  };

  handleIconClick = () => {
    this.fileInputRef.current.click(); 
  };
  handlePwdPopupOpen = () => {
    this.setState({passwordChangePopup:true})
  }
  handleConfirmPwdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({newPwd:e.target.value})
  }
  handlePwdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password:e.target.value})
  }
  handleConfirmNewPwdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({confirmNewPassword:e.target.value})
  }
  toggleShowPwd = () => {
    this.setState((prevState) => ({ showPwd: !prevState.showPwd}))
  }  
  toggleShowNewConfirmPwd = () => {
    this.setState((prevState) => ({ showConfirmNewPwd: !prevState.showConfirmNewPwd}))
  }  
  toggleShowConfirmPwd = () =>{
    this.setState((prevState) => ({ showConfirmPwd: !prevState.showConfirmPwd}))
  }
  handleDeletePopupOpen = () => {
    this.setState({accountDeletePopup:true})
  }
   // Validation rules
   hasUppercase = () => /[A-Z]/.test(this.state.password);
   hasLowercase = () => /[a-z]/.test(this.state.password);
   hasNumber = () => /\d/.test(this.state.password);
   hasSpecialCharacter = () => /[!@#$%^&*(),.?":{}|<>]/.test(this.state.password);

   deleteAccountApi = () => {
    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/user_profiles/destroy_user"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  changePasswordApi = () => {
    const {password,newPwd,confirmNewPassword} = this.state;
    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
      token: this.state.token
    };
    const data = {
      "current_password": password,
      "new_password": newPwd,
      "password_confirmation": confirmNewPassword
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePwdApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/user_profiles/change_password"
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  urlToFile = async (url:any, filename:any, mimeType:any) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };     

  profileUpdateApi = async () => {
    const { xurl, token,linkedinUrl, selectedImage, instagramUrl, facebookUrl, pinterestUrl, fullName, bio, email, location } = this.state;
  
    const header = {
       token:token
    };
  
    const formData = new FormData();
  
    formData.append("user_profile[bio]", bio);
    formData.append("user_profile[email]", email);
    formData.append("user_profile[full_name]", fullName);
    formData.append("user_profile[location]", location);
    formData.append("user_profile[linkedin_url]", linkedinUrl);
    formData.append("user_profile[instagram_url]", instagramUrl);
    formData.append("user_profile[facebook_url]", facebookUrl);
    formData.append("user_profile[pinterest_url]", pinterestUrl);
    formData.append("user_profile[x_url]", xurl);
  
    if (selectedImage) {
      const file = await this.urlToFile(selectedImage, "profile_picture.jpg", "image/jpeg");
      formData.append("user_profile[profile_picture]", file);
    }
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.profileUpdateApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/user_profiles/update_profile"
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData 
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProfileData =  () => {
    const {token,loginId} = this.state;
    
    const header = {
       token:token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/user_profiles/${loginId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  // Customizable Area End
}
