import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
import moment, { Moment } from "moment";
import Slider from "react-slick";
import { toast } from 'react-toastify';
import React from "react";
import { TimeSlot } from "./types";
export interface AppointmentData {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    price: number | null;
    duration: number | null;
    best_seller: boolean;
    sub_category_id: number;
    service_image: {
      id: number;
      url: string;
    };
    created_by: string;
    sub_category: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      parent_id: number | null;
      rank: number | null;
      category_id: number | null;
    };
  };
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  appointmentData: any;
  bookingSlotId:any;
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
  sessionValue:any;
  selectedDate:any;
  selectedTime:any;
  showCalendar: boolean,
  activeIndex: number;
  timeslot:any;
  validationError: string;
  currentMonth:any;
  currentYear:any;
  categoryID:any;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  appointmentApiCallId?: string;
  mainSliderRef: React.RefObject<Slider>;
  thumbnailSliderRef: React.RefObject<Slider>;
  bookingSlotApiCallId?: string;
  bookNowSlotApiCallId?:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      appointmentData:"",
     
      categoryID:null,
      bookingSlotId:"",
      id: 0,
      activeIndex: 0,
      showCalendar:false,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      selectedDate:null,
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
      sessionValue:null,
      selectedTime:null,
      timeslot:[],
      validationError: "",
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear()
      // Customizable Area End
    };

    // Customizable Area Start
    this.mainSliderRef = React.createRef<Slider>();
    this.thumbnailSliderRef = React.createRef<Slider>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const loginApiToken = await getStorageData("loginToken")
    this.setState({token:loginApiToken})
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
         if (apiRequestCallId === this.appointmentApiCallId) {
          this.setState({appointmentData : responseJson?.data,
            timeslot:responseJson?.data?.attributes?.availability?.time_slots
          })
         }
        }
      if (apiRequestCallId === this.bookingSlotApiCallId) {
        this.setState({bookingSlotId : responseJson?.booking?.id,showCalendar:false})
       }
      }
      if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        let data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
        if (data) {
          this.setState({ categoryID: data }, () => {
            this.showAppointmentApi();
          });
        }
      }
      
    // Customizable Area End
  };

  // Customizable Area Start
  getHighlightedDates = (): Date[] => {
    if (Array.isArray(this.state?.timeslot)) {
      return this.state.timeslot
        ?.map((slot: any) => {
          if (slot?.availability_date) {
            const [day, month, year] = slot.availability_date.split("/");
            const date = new Date(Number(year), Number(month) - 1, Number(day));
  
            // Only allow highlighting for the current and upcoming month
            if (
              (date.getMonth() === this.state.currentMonth && date.getFullYear() === this.state.currentYear) ||
              (date.getMonth() === this.state.currentMonth + 1 && date.getFullYear() === this.state.currentYear)
            ) {
              return isNaN(date.getTime()) ? null : date;
            }
          }
          return null;
        })
        .filter((date): date is Date => date !== null);
    }
    return [];
  };
  handleDateChange = (date: any) => {
    if (this.isSelectableDate(date)) {
      this.setState({
        selectedDate: date,
        selectedTime: null,
        validationError: "",
      });
    }
  };
  dayClassName = (date: Date) => {
    return this.isSelectableDate(date) ? "" : "disabled-date";
  };
  isSelectableDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isSameOrFutureMonth =
      (date.getMonth() === this.state.currentMonth && date.getFullYear() === this.state.currentYear) ||
      (date.getMonth() === this.state.currentMonth + 1 && date.getFullYear() === this.state.currentYear);
    return isSameOrFutureMonth && date >= today;
  };
  getFilteredTimeSlots = () => {
    const selectedDateStr = moment(this.state.selectedDate)
    .utcOffset(60)
    .format('DD/MM/YYYY'); 
    
    return this.state.appointmentData?.attributes?.availability?.time_slots?.filter((slot: any) => {
      return slot?.availability_date === selectedDateStr;
    }) || [];
  };

  

 
 

  handleThumbnailClick(index: number) {
    if (this.mainSliderRef.current) {
      this.mainSliderRef.current.slickGoTo(index);
    }
    this.setState({ activeIndex: index });
  }
  showAppointmentApi = () => {
    const {categoryID} = this.state
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      // token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appointmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/services/${categoryID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  handleTimeChange = (time:any) => {
    this.setState((prevState) => ({
    selectedTime: prevState.selectedTime === time ? null : time, 
    validationError: ""
  }));
  };
  getAppointmentList = (token: string) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token,
    };

    const searchParams = {
      service_provider_id: "1",
      availability_date: this.state.available_date.format(
        configJSON.serverFormat
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppointmentsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${new URLSearchParams(
        searchParams
      ).toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  handleCancel = () => {
    this.setState({
      selectedDate: null,
      selectedTime: null,
    });
  };
  handleCalendarOpen = () => {
    this.setState({showCalendar : true})
  }

  
  handleClose = () => {
    this.setState({showCalendar:false})
  }
  appointmentContinueBtnBooking  = async () => {
    const {selectedDate,selectedTime} = this.state
    const formattedDate = selectedDate?.toLocaleDateString('en-GB');
    const selectedTimeFrom = selectedTime?.from;
    if (!selectedDate) {
      toast.error("Please select a date.");
      return;
    }
  
    if (!selectedTime) {
      toast.error("Please select a time slot.");
      return;
    }
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
       token: this.state.token,
    };
    const apiEndPoint = `bx_block_appointment_management/bookings?service_id=21&availability_date=${formattedDate}&start_time=${selectedTimeFrom}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookingSlotApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  appointmentBookNow  = async () => {
    const { bookingSlotId,selectedDate, selectedTime } = this.state;

    if (!selectedDate || !selectedTime) {
      this.setState({ validationError: "You need to pick a session first" });
      return;
    }
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
       token: this.state.token,
    };
    const apiEndPoint = `bx_block_order_management/buy_services?service_id=21&booking_id=${bookingSlotId}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookNowSlotApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleBeforeChange = (_: any, nextIndex: number) => {
    this.setState({ activeIndex: nextIndex });
  };
 
 

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }
  
  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }
  handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    this.setState({
      sessionValue: isNaN(newValue) ? 0 : newValue,
    });
  };

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  navigateToAddAppointment() {
    // Merge Engine - Navigation - Start
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddAppointment'
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
    // Merge Engine - Navigation - End
  }

  // Customizable Area End
}
