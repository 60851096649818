import React from "react";
import LocalListingAdsController, { Props } from "./LocalListingAdsController";
// Customizable Area Start
import EastIcon from '@mui/icons-material/East';
import {
  Box,
  Button,
  Typography,InputAdornment,
  Grid,styled, Card,CardMedia,CardContent,
  TextField,Divider,InputLabel,Dialog,DialogTitle,IconButton,DialogContent,Select,MenuItem,DialogActions,Tooltip
} from '@mui/material';
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { ToastContainer } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Rating from '@mui/material/Rating';
import Header from "../../landingpage/src/Header.web";
import {imageUpload} from './assets';
import AddIcon from '@mui/icons-material/Add';
import {searchIcon} from './assets'
const timeOptions = [
  "12:00 AM", "12:30 AM", "1:00 AM", "1:30 AM", "2:00 AM", "2:30 AM",
  "3:00 AM", "3:30 AM", "4:00 AM", "4:30 AM", "5:00 AM", "5:30 AM",
  "6:00 AM", "6:30 AM", "7:00 AM", "7:30 AM", "8:00 AM", "8:30 AM",
  "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
  "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM",
  "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM",
  "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM", "8:30 PM",
  "9:00 PM", "9:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"
];
// Customizable Area End

export default class LocalListingAds extends LocalListingAdsController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {createListingData,calendarOpen,isChecked} = this.state;
   // Customizable Area End

    return (
      // Customizable Area Start
      <Box>
        <Header navigation={undefined} id={''} />
      <Box sx={{ padding: {xs:"34px 22px 0px 20px",sm:"43px 26px 0px 39px",md:"43px 26px 0px 39px",lg:"43px 80px 0px 80px"} }}>
        <Box marginBottom="40px" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography sx={{fontFamily:"Poppins",fontWeight:600,fontSize:"30px",color:"#101828"}}>
          Your Listings
        </Typography>
        <TextField placeholder="Search" 
         fullWidth 
              sx={{
                width:"320px",height:"44px",borderRadius:"8px",
                "& .MuiInputBase-input::placeholder": {
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#667085",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "Poppins",
                  paddingLeft: "10px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  fontFamily: "Poppins",
                  width: "320px",
                  height: "44px",
                  "&:hover fieldset": {
                    border: "1px solid #D6D3D1",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #D6D3D1",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <img src={searchIcon} alt="searchIcon" style={{
                    height: "20px", width: "20px", color: "#667085"
                  }} />
                ),
              }}
        />
        </Box>
          <Grid container spacing={3} padding="10px 0px">
              {createListingData  && <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card sx={{ boxShadow: 2, borderRadius: "16px",height:"460px" }}>
                  <CardMedia
                    component="img"
                    height="172"
                    image={(Array.isArray(createListingData?.attributes?.listing_images) && createListingData?.attributes?.listing_images.length > 0) && createListingData?.attributes?.listing_images[0]?.url}
                    alt="serviceImage"
                  />
                  <CardContent>
                    <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "60.33px", borderRadius: "17px", fontFamily: "Poppins", fontWeight: 700, fontSize: "10px", color: "#404145", backgroundColor: "#EFEFF0" }}>
                      {createListingData?.type}
                    </Typography>
                    <Box height="163px">
                    <Tooltip title={createListingData?.attributes?.title} arrow>
                    <Typography sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1, 
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: "break-word", 
                      marginTop: "13px", fontFamily: "Poppins", fontWeight: 700, fontSize: "17px", color: "#404145" }}>
                      {createListingData?.attributes?.title}
                    </Typography>
                    </Tooltip>
                    <Box display="flex" flexDirection="row">
                      <Box style={{ height: "12px", width: "13px", marginTop: "4px" }}>
                        <Rating
                          sx={{
                            "& .MuiSvgIcon-root": {
                              height: "12px", width: "13px", color: "#6D8E55"
                            },
                          }}
                          name="customized-10" defaultValue={2} max={1} />
                      </Box>
                      <Box marginRight="12px">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: "#6D8E55" }}>
                          4.8
                        </Typography>
                      </Box>
                      <Box marginRight="12px">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "13px", color: "#B5B6BA" }}>
                          (227)
                        </Typography>
                      </Box>
                      <Box>
                        <QueryBuilderIcon style={{ height: "13px", width: "12px" }} /> <span style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: "14px", color: "#62646A" }}>{createListingData?.attributes?.duration} hours</span>
                      </Box>
                    </Box>
                    <Typography sx={{ marginTop: "5px", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: "#222325" }}>
                      by {createListingData.attributes?.created_by}
                    </Typography>
                    <Tooltip title={createListingData?.attributes?.description} arrow>
                    <Typography sx={{ 
                      isplay: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      fontFamily: "Poppins", fontWeight: 400, fontSize: "12px", color: "#95979D" }}>
                      {createListingData?.attributes?.description}
                    </Typography>
                    </Tooltip>
                    <Typography sx={{ marginTop: "15px", fontFamily: "Poppins", fontWeight: 400, fontSize: "15px", color: "#404145" }}>
                      {createListingData.attributes?.price ? `$${createListingData?.attributes?.price}` : ""}
                    </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{textTransform: "none", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px", color: "#44041C", marginTop: "32.42px", borderRadius: "8px", border: "1px solid #44041C",
                      "&:hover": {
                        backgroundColor: "#44041C", 
                        color: "#FFFFFF", 
                      },
                      "&:focus": {
                        backgroundColor: "#44041C",
                      },
                      "&:active": {
                        backgroundColor: "#44041C",
                      },
                    }}
                    >
                      Edit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>}

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box
              sx={{
                border: "2px dashed #A8A29E",
                height: "425px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: 2,flexDirection:"column",borderRadius:"16px"
              }}
              onClick={this.handleDialogueOpen}
            >
              <Typography sx={{fontFamily:"Poppins",fontWeight:700,fontSize:"22px",color:"#4A4A4A"}}>
                + Create a Listing
              </Typography>
              <Typography sx={{fontFamily:"Poppins",fontWeight:400,fontSize:"17px",color:"#4A4A4A"}}>Click to add a new listing</Typography>
            </Box>
          </Grid>
          </Grid>

          <Dialog open={this.state.openDialogue} onClose={this.handleClose} sx={{borderRadius:"16px",width:"100%",display:"flex",textAlign:"center",
            "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",width:"100%"
          },
          "& .MuiPaper-root": {
            width: "600px", 
            maxWidth: "90%", 
            borderRadius: "12px", 
            padding: "20px", 
          },
          }}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{fontFamily:"Poppins",fontWeight:700,fontSize:"24px",color:"#000000"}}>Add Listing</Typography>
          <IconButton onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
         
        <DialogContent>
        <Box width="100%">
            <Divider sx={{ width: "100%", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
        </Box>
        <ToastContainer />
        <InputLabel sx={styles.inputLabelText}>Title</InputLabel>
          <TextField
            name="title"
            fullWidth
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", 
                },
                "& .MuiInputBase-input": {
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#000000", 
                },
              },
            }}
            value={this.state.title}
            onChange={this.handleTitleChange}
          />
              <InputLabel sx={styles.inputLabelText}>Description</InputLabel>
              <TextField
                name="description"
                fullWidth
                multiline
                rows={3}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#CBD5E1",
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000000",
                    },
                  },
                }}
                value={this.state.description}
                onChange={this.handleDescriptionChange}
                inputProps={{ maxLength: 600 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{fontWeight:400 ,fontFamily:"Poppins",fontSize: "14px", color: "#94A3B8", position: "absolute", bottom: 5, right: 10 }}>
                        {this.state.description.length}/600
                      </span>
                    </InputAdornment>
                  )
                }}           
              />
          <InputLabel sx={styles.inputLabelText}>Category</InputLabel>
              <Select
                displayEmpty
                fullWidth
                name="category"
                sx={{
                  borderRadius: "8px",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  textAlign: "left",
                  "& .MuiSelect-select": {
                    fontFamily: "Poppins", 
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "left",
                  },
                  "& fieldset": {
                    borderRadius: "8px",
                    color: "#CBD5E1",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#CBD5E1",
                    textAlignLast: "left",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#475569",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#475569",
                  },
                }}
                value={this.state.selectedCategory}
                onChange={this.handleCategoryChange}
                IconComponent={KeyboardArrowDownIcon}
              >
                {this.state?.categorySubData &&
                  this.state?.categorySubData?.map((item: any) => (
                    <MenuItem
                    sx={{
                      fontFamily: "Poppins", 
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000000",
                    }}
                    key={item.attributes.id} value={item.attributes.id}>
                      {item.attributes.name}
                    </MenuItem>
                  ))}
              </Select>

          <InputLabel sx={styles.inputLabelText}>Sub-category</InputLabel>
              <Select
                displayEmpty
                fullWidth
                name="subCategory"
                sx={{
                  borderRadius: "8px",
                  fontFamily: "Poppins", 
                  fontSize: "16px",
                  textAlign: "left",
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                  "& .MuiSelect-select": {
                    fontFamily: "Poppins", 
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "left",

                  },
                }}
                IconComponent={KeyboardArrowDownIcon}
                value={this.state.selectedSubCategory} 
                onChange={this.handleSubCategoryChange} 
              >
                {this.state.subCategories.map((sub: any) => (
                  <MenuItem key={sub.id} value={sub.id}
                  sx={{
                    fontFamily: "Poppins", 
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#000000",
                  }}>
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>


          <InputLabel sx={styles.inputLabelText}>Price</InputLabel>
          <TextField
            name="price"
            type="text"
            fullWidth
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", 
                },
                "& .MuiInputBase-input": {
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000000",
                    },
              },
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={this.state.price}
            onChange={this.handlePriceChange}
          />
          <InputLabel sx={styles.inputLabelText}>Duration in hours</InputLabel>
          <TextField
            name="duration"
            fullWidth
            type="text"
            sx={{
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": {
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "#CBD5E1",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#CBD5E1", 
                },
                "& .MuiInputBase-input": {
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000000",
                },
              },
            }}
            value={this.state.duration}
            onChange={this.handleDurationChange}
          />
          <Box mt={2} 
          textAlign="start"
          >
            <Typography sx={styles.inputLabelText}>Availabilities</Typography>
                <Box sx={{ marginTop: "4px" }}>
                  {Object.keys(this.state.selectedSlots).map((date) => {
                    const formattedDay = dayjs(date).format("dddd"); // Get the day name
                    const formattedSlots = this.state.selectedSlots[date]
                      .map((slot:any) => `${slot.start} -> ${slot.end}`)
                      .join(", ");

                    return (
                      <Typography key={date} sx={{ fontFamily:"Poppins",color:"#1C1917",fontWeight:400, fontSize: "16px", mb: 1 }}>
                        {formattedDay}, {formattedSlots}
                      </Typography>
                    );
                  })}
                </Box>
           <Button sx={{color:"#44041C",marginLeft:"-6px"}} onClick={this.handleCalendarOpen}>
              <AddIcon sx={{color:"#44041C"}} /> <span style={{textTransform:"none",fontFamily:"Poppins",fontWeight:700,fontSize:"16px"}}>Add</span>
            </Button>
          </Box>
              <div style={{ display: "flex", gap: "10px", marginBottom: "10px", flexWrap: "wrap" }}>
                {/* Uploaded Images */}
                {this.state.uploadedImage.map((image: any) => (
                  <div key={image.id} style={{ position: "relative" }}>
                    <img
                      src={image.id}
                      alt="Uploaded"
                      style={{ width: "80px", height: "80px", borderRadius: "8px" }}
                    />
                    <button
                      data-test-id="uploadImage"
                      onClick={() => this.removeImage(image.id)}
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-3px",
                        background: "#FEE2E2",
                        color: "#DC2626",
                        border: "none",
                        cursor: "pointer",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      ×
                    </button>
                  </div>
                ))}

                {/* Show Add Button Only If Images Exist */}
                {this.state.uploadedImage.length > 0 && (
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      border: "2px dashed #44041C",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={this.handleBoxClick}
                  >
                    <AddIcon sx={{ color: "#44041C" }} />
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      style={{ display: "none" }}
                      data-test-id="handleImageUpload"
                      onChange={this.handleImageUpload}
                      id="upload-button"
                      ref={this.fileInputRef}
                    />
                  </div>
                )}
              </div>

              {/* Show Upload Box if No Images */}
              {this.state.uploadedImage.length === 0 && (
                <>
                  <InputLabel sx={styles.inputLabelText}>Images</InputLabel>
                  <Box
                    data-test-id="uploadImages"
                    p={2}
                    borderRadius="14px"
                    border="2px dashed #44041C"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    marginTop="4px"
                    onClick={this.handleBoxClick}
                    sx={{ cursor: "pointer" }}
                    onDragOver={this.handleDragOver}
                    onDragLeave={this.handleDragLeave}
                    onDrop={this.handleDrop}
                  >
                    <img src={imageUpload} alt="imageUpload" style={{ width: "32px", height: "32px" }} />
                    <Box display="flex" flexDirection="column">
                      <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "12px", color: "#475569" }}>
                        Click to upload <span style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "12px", color: "#475569" }}>or drag and drop</span>
                      </Typography>
                      <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "12px", color: "#475569" }}>
                        (png, jpg)
                      </Typography>
                    </Box>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      style={{ display: "none" }}
                      data-test-id="handleImageUpload"
                      onChange={this.handleImageUpload}
                      id="upload-button"
                      ref={this.fileInputRef}
                    />
                  </Box>
                </>
              )}
        <Box width="100%">
          <Divider sx={{ width: "100%", marginTop: "32px", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} sx={{textTransform:"none",borderRadius:"8px",width:"91px",height:"44px",border:"1px solid #44041C",color:"#44041C",fontFamily:"Poppins",fontWeight:700,fontSize:"16px"}}>
            Cancel
          </Button>
          <Button data-test-id="saveBtn" onClick={this.createListingCards} variant="contained" sx={{textTransform:"none",borderRadius:"8px",width:"73px",height:"44px",backgroundColor:"#44041C",color:"#FFFFFF",fontFamily:"Poppins",fontWeight:700,fontSize:"16px",
                "&:hover": {
                  backgroundColor: "#44041C",
                  color: "#FFFFFF",
                },
                "&:focus": {
                  backgroundColor: "#44041C",
                },
                "&:active": {
                  backgroundColor: "#44041C",
                },
              }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {calendarOpen && 
       <Dialog
       sx={{
           padding: "32px",
           "& .MuiPaper-root": {
             borderRadius: "16px",
             maxWidth: "448px",
             width: "448px",
            //  height: "640px",
             overflow: "hidden",
             "@media (max-width: 768px)": {
               width: "90%", 
               height: "auto",
             },
           },
         }}
         open={calendarOpen}
       >
         <DialogContent>
            <Box sx={{ borderRadius: "16px", overflow: "hidden", display: "flex", justifyContent: "center",flexWrap: "wrap" }}>
             <Box sx={{
               width: "100%", justifyItems: "start", alignItems: "center",
               "@media (max-width: 768px)": {
                 padding: "16px",
               },
             }}
                >
               <Box sx={{
                 display: "flex", flexDirection: "row", justifyContent:"space-between",
                 alignItems: "center",  // Ensures vertical alignment
                 width: "100%", 
                  "@media (max-width: 768px)": {
                  //  gap: "20px", 
                   flexDirection: "column",
                   alignItems: "center",
                 },
               }}>
                 <Box>
                   <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px" }}>Choose your date & time</Typography>
                 </Box>
                 <Box sx={{
                     backgroundColor: "#F8FAFC",
                     width: "56px",
                     height: "56px",
                     alignItems: "center",
                     borderRadius: "30px",
                     display: "flex",
                     justifyContent: "center"
                    }}>
                   <CloseIcon
                     data-test-id="closeCalendar"
                     onClick={()=>this.setState({calendarOpen:false})}
                     style={{ cursor: "pointer",color:"#64748B" }}
                   />
                 </Box>
               </Box>
               <Box width="100%">
                 <Divider sx={{ width: "100%", marginTop: "32px", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
               </Box>

               <Box 
               sx={{marginTop:"32px", 
               width:"100%" ,display:"flex" ,
               justifyContent:"space-between" ,
               flexDirection:{xs:"column",sm:"row",md:"row",lg:"row"}}}>
                 <Box>
                   <StyledDatePickerWrapper>
                     <DatePicker
                       onChange={this.handleDateChange}
                       inline
                       value={this.state.selectedDate}
                       filterDate={(date) => date >= new Date(new Date().setHours(0, 0, 0, 0))} 
                       formatWeekDay={(day) => {
                         const dayIndex = new Date(day).getDay();
                         const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                         return weekDays[dayIndex];
                       }}
                       calendarStartDay={1}
                       
                       
                      />
                   </StyledDatePickerWrapper>
                 </Box>
                </Box>
                <Box width="100%">
                 <Divider sx={{ marginTop:"32px",width: "100%", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
               </Box>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent:"space-between",
                 alignItems: "center",

                 width: "100%", }}>
                      <Box marginTop="24px">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "13.71px", color: "#3C413F" }}>
                          What hours are you available?
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#FFFFFF", 
                          width: "51.43px",
                          height: "48px",
                          marginLeft:"75px",
                          marginTop:"24px",
                          borderRadius: "12px", 
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #E2E8F0",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                          },
                        }} onClick={this.handleAddSlot}
                      >
                        <AddIcon sx={{ color: "#64748B", fontSize: "24px" }} />
                      </Box>

                      <Box>
                    </Box>
               </Box>
               <Box sx={{ mt: 2 }}>
          {Object.keys(this.state.selectedSlots).map((date) => (
            <Box key={date} sx={{ mb: 3 }}>
              <Box sx={{
                backgroundColor: "#F7F7F9",
                border: "1px solid #CBD5E1",
                borderRadius: "8px",
                width:"351px",
                padding: "8px 12px",
                display: "inline-block",
                height:"26px",marginBottom:"16px"
              }}>
              <Typography sx={{margin:"3px" ,fontWeight: 700, fontSize: "14px", mb: 1,fontFamily:"Poppins",color:"#1C1917" }}>
                {dayjs(date).format("dddd, MMMM D")}
              </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {this.state.selectedSlots[date].map((slot:any, index:number) => (
                  <Box key={index} sx={{ marginBottom:"16px",display: "flex", alignItems: "center", gap: 1 }}>
                    {/* ⏰ Start Time */}
                    <TextField
                      select
                      SelectProps={{
                        IconComponent: KeyboardArrowDownIcon, 
                      }}
                      onChange={(e) => this.handleTimeChange(date, index, "start", e.target.value)}
                      sx={{
                        borderRadius: "8px",width:"110px",height:"48px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                          "& .MuiInputBase-input": {
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: 400,
                                color: "#000000",
                          },
                          "&:hover fieldset": {
                            borderColor: "#CBD5E1",
                          },
                         
                        },
                      }}
                      data-test-id="startSlot"
                      value={slot.start}
                      
                    >
                      {timeOptions.map((time) => (
                        <MenuItem key={time} value={time}>{time}</MenuItem>
                      ))}
                    </TextField>

                    <EastIcon sx={{ color: "#64748B" }} />

                    {/* ⏳ End Time */}
                    <TextField
                      select
                      data-test-id="slotEnd"
                      
                      value={slot.end}
                      
                      SelectProps={{
                        IconComponent: KeyboardArrowDownIcon, 
                      }}
                      onChange={(e) => this.handleTimeChange(date, index, "end", e.target.value)}
                      sx={{
                        borderRadius: "8px",width:"110px",height:"48px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                          
                          "&:hover fieldset": {
                            borderColor: "#CBD5E1",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                          "& .MuiInputBase-input": {
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: 400,
                                color: "#000000",
                          },
                        },
                      }}
                      
                    >
                     
                      {timeOptions.map((time) => (
                        
                        <MenuItem key={time} value={time}>{time}</MenuItem>
                      
                      ))}
                    </TextField>

                    {/* ❌ Remove Slot */}
                    <IconButton data-test-id="closeIconId" onClick={() => this.handleRemoveSlot(date, index)} sx={{ color: "#64748B" }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
                    <Box className="checkboxContainerLast" style={{ display: "flex", alignItems: "center",marginTop:"16px" }}>
                      <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                        <input
                          data-testid="checkbox"
                          type="checkbox"
                          checked={isChecked}
                          onChange={this.handleCheckboxChange}
                          style={styles.checkboxStyle(isChecked)}
                          name="agree"
                          data-test-id="agreeCheck"
                        />
                        {isChecked && (
                          <span
                            style={styles.isCheckedStyle}
                          >
                            ✓
                          </span>
                        )}
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#0F172A",
                          }}
                        >
                          Apply on all Wednesdays
                        </Typography>
                      </label>
                    </Box>
                    <Box width="100%">
                      <Divider sx={{ marginTop: "32px", width: "100%", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
                    </Box>
               <Box sx={{padding:{xs:"0px",sm:"15px",md:"28px",lg:"28px"}, display:"flex" ,flexDirection:"row", justifyContent:"space-between", gap:"20px", justifySelf:"end"}}>
                 <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button data-test-id="cancel" onClick={() => this.setState({ calendarOpen: false })}
                    sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                    Cancel
                  </Button>
                 </Box>
                 <Box data-test-id="continue" sx={{ width: "108px", height: "44px", borderRadius: "8px", backgroundColor: "#44041C", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                   <Button 
                   onClick={()=>this.setState({calendarOpen:false})}
                   sx={{textTransform:"none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                     Continue
                   </Button>
                 </Box>
               </Box>
            </Box>
           </Box>
         </DialogContent>
       </Dialog>}
      </Box>
      </Box>
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  inputLabelText:{
    fontFamily:"Poppins !important",
    fontSize:"14px",
    color:"#334155",
    fontWeight:700,
    textAlign:"start",
    marginTop:"16px"
  },
  isCheckedStyle: {
    position: "absolute" as "absolute",
    left: "124px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  checkboxStyle: (isChecked:boolean) => ({
    appearance: "none" as "none",
    WebkitAppearance: "none" as "none",
    MozAppearance: "none" as "none",
    width: "20px",
    height: "20px",
    border: "2px solid #6E7781",
    borderRadius: "6px",
    outline: "none",
    marginRight: "8px",
    cursor: "pointer",
    backgroundColor: isChecked ? "#44041C" : "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative" as "relative",
  }),
  slide: {
    textAlign: "center",
    background: "#fff",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "0 auto",
  },
  policyText:{
    fontFamily: "Poppins !important",
    textDecoration: "underline", 
    cursor: "pointer"
  },
  layout: {
    paddingLeft: "9%",
  },
};
const StyledDatePickerWrapper = styled("div")`
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
    border-radius: 15px !important;
    background-color: #44041C !important;
    color: #FFFFFF !important;
}

  .react-datepicker {
    border: none !important;
  },
  .hidden-date {
  visibility: hidden;
}
  .react-datepicker__month-container {
    width: 384px !important;
    // height: 372px !important;
  },
  .react-datepicker__day--highlighted {
    color: #263238 !important;
    background-color:#44041C17 !important;
    border-radius: 18px
  }
  .react-datepicker__day--selected {
  background-color: #44041C !important;
  color: white !important;
  border-radius: 50%; /* Optional: to make it look like a circular button */
},
.react-datepicker__week {
  margin-bottom: 10px
},
  .react-datepicker__current-month {
  font-family: 'Poppins' !important;
  font-weight: 300; 
  font-size: 20px !important; / Remove quotes */
  color: #263238;
  margin-bottom : 20px
}
 .react-datepicker__day-names {
  display: flex;
  justify-content: space-around;

}
  .react-datepicker__day {
   font-family: 'Poppins' !important; 
  font-weight: 400; 
  font-size: 17.14px !important; 
  color: #BFC9C9;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__header {
   border-bottom : none;
   background-color:#ffffff
  }
  .react-datepicker__navigation--previous {
   left:54px;
   top:9px
  },

   .react-datepicker__navigation--next {
    top : 9px;
    right : 50px
    }
   .react-datepicker__navigation svg {
  fill: grey !important; /* For SVG arrows */
}
  .react-datepicker__day-names {
   font-family: 'Poppins' !important; 
  font-weight: 400; 
  font-size: 13.71px !important; 
  color: #3C413F;
  }
  @media only screen and (max-width: 374px) {
  .react-datepicker__month-container {
    width: 180px !important; 
  }

  .react-datepicker__day {
    font-size: 12px !important; 
  }

  .react-datepicker__current-month {
    font-size: 14px !important; 
  }
}
   @media only screen and (max-width: 375px) {
  .react-datepicker__month-container {
    width: 200px !important;
  }

  .react-datepicker__day {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 576px) {
  .react-datepicker__month-container {
    width: 250px !important;
  }

  .react-datepicker__day {
    font-size: 15px !important;
  }

  .react-datepicker__current-month {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .react-datepicker__month-container {
    width: 300px !important;
  }

  .react-datepicker__day {
    font-size: 16px !important;
  }

  .react-datepicker__current-month {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .react-datepicker__month-container {
    width: 330px !important;
  }

  .react-datepicker__day {
    font-size: 17px !important;
  }

  .react-datepicker__current-month {
    font-size: 20px !important;
  }
}
  `
// Customizable Area End
