export const leftarrow = require("../assets/leftarrow.png");
export const likeIcn = require("../assets/likeIcn.png");
export const male = require("../assets/male.png");
export const produc = require("../assets/produc.png");
export const girl = require("../assets/girl.png");
export const Rectangle = require("../assets/Rectangle.png");
export const RIGHTTICK = require("../assets/RightTick.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imageUpload = require("../assets/imageUpload.png");
export const searchIcon = require("../assets/searchIcon.png");
