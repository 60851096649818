import React from "react";
// Customizable Area Start
import {
  InputAdornment,IconButton, Paper,
  Stepper,Divider,
  Step, StepConnector,
  StepLabel, Box,styled,TextField, Button, Avatar,  InputLabel, Typography, Grid,
  MenuItem,
  Select
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { ToastContainer } from 'react-toastify';
import "@mui/styles";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../../blocks/landingpage/src/Header.web'

// Customizable Area End
import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-root": {
    top: 12,
  },
  "& .MuiStepConnector-line": {
    borderTopWidth: 4,
    transition: "border-color 0.3s ease-in-out",
  },
  "&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line": {
    borderColor: "#4A192C",
  },
}));


const CustomStepLabel = styled(StepLabel)(() => ({
  "& .MuiStepLabel-label": {
    color: "#D3D3D3",
    fontSize: "16px", 
    fontFamily: "Poppins, sans-serif",
    transition: "color 0.3s ease-in-out",
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: "#4A192C",
    fontWeight: 600,
  },
}));

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
  // Customizable Area End
  }
  // Customizable Area Start
  // console.log("timesZone list", timeZoneList)
  

  

  CustomStepProfileIcon = ({ active, completed }: { active: boolean; completed: boolean }) => {

    const backgroundColor = completed || active ? "#4A192C" : "transparent";
  
  
  
    const borderColor = active || completed ? "#4A192C" : "#CFD6DC";
  
    return (
      
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          backgroundColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          border: `2px solid ${borderColor}`,
          
        }}
      >
        {completed ? (
          <CheckIcon 
          style={{ color: "#FFFFFF", fontSize: "18px" }} />
        ) : (
          active && (
            <div
              style={{
                width: "14px",
                height: "14px",
  
  
                backgroundColor: "#4A192C",
                position: "absolute",
                border: "5px solid #fff",
                borderRadius: "50%",
               
              }}
            />
          )
        )}
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const steps = ["Personal Info", "Experience", "Payment Info"];

    
    const {firstName,last_name,timeZone,description,title} = this.state
    return (
      <>
      <Header navigation={undefined} id={""} />
      <Box
        sx={{
          padding: { xs: "30px 20px 46px 20px", sm: "30px 20px 46px 20px", md: "30px 214px 46px 214px", lg: "30px 214px 46px 214px" }
        }}
      >
        <Paper elevation={0} style={{boxShadow: "0px 0px 10px rgb(189 179 179 / 40%)",padding: 20, marginTop: 20 }}>
        <Stepper activeStep={this.getActiveStep()} alternativeLabel connector={<CustomConnector />}>
  {steps.map((label, index) => (
   
   <Step key={index} 
   completed={index < this.getActiveStep()}>
     
      <CustomStepLabel StepIconComponent={this.CustomStepProfileIcon}>
       
        {label}
      
      </CustomStepLabel>
    
    </Step>
  ))}
</Stepper>
          <Typography sx={{marginTop:"40px",fontFamily:"Poppins",fontWeight:700,fontSize:"24px",color:"#000000"}}>
            Personal Info
          </Typography>
          <ToastContainer />
          <Divider sx={{ marginTop: "40px",width:"100%" }} color="#CBD5E1" />
          <Grid container spacing={2} sx={{ marginTop: "40px" }}>
            {/* Full Name */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={4}>
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Full Name</InputLabel>
              </Grid>
              <Grid item xs={8} container spacing={1}>
                <Grid item xs={6}>
                  <TextField fullWidth name="firstName" placeholder="First Name"
                  data-test-id="firstName"
                  value={firstName}
                  onChange={(e) => this.setState({firstName:e.target.value})}
                  sx={{
                    borderRadius: "8px", 
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      height: "56px",
                      "& .MuiInputBase-input":{
                        fontFamily: "Poppins",
                      },
                      "& fieldset": {
                        borderRadius: "8px",
                        border: "1px solid #CBD5E1", 
                      },
                      "&:hover fieldset": {
                        border: "1px solid #CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid #CBD5E1", 
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                        color: "#94A3B8",
                        fontWeight: 400,
                        fontSize: "16px",
                      },
                    },
                  }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth 
                  data-test-id="lastName"
                 
                  onChange={(e) => this.setState({last_name:e.target.value})}
                  
                  placeholder="Last Name" name="lastName" 
                  
                  value={last_name}
                 
                  sx={{
                    borderRadius: "8px", 
                    "& .MuiOutlinedInput-root": {
                     
                     
                      borderRadius: "8px",
                      height: "56px",
                      
                      "&.Mui-focused fieldset": {
                        border: "1px solid #CBD5E1", 
                      },
                      "& .MuiInputBase-input":{
                        fontFamily: "Poppins",
                      },
                      "& fieldset": {
                        borderRadius: "8px",
                        border: "1px solid #CBD5E1", 
                      },
                      "&:hover fieldset": {
                        border: "1px solid #CBD5E1",
                      },
                      
                      
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                        color: "#94A3B8",
                        fontWeight: 400,
                        fontSize: "16px",
                      },
                    },
                  }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Title */}
            <Grid item xs={12} container alignItems="center" sx={{ marginTop: "24px" }}>
              <Grid item xs={4}>
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Title</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField fullWidth 
                
                value={title}
                
                onChange={(e) => this.setState({title:e.target.value})}
                
                data-test-id="title"
                sx={{
                  borderRadius: "8px", 
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    height: "56px",
                    "& fieldset": {
                      borderRadius: "8px",
                      border: "1px solid #CBD5E1", 
                    },
                    "& .MuiInputBase-input":{
                        fontFamily: "Poppins",
                      },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #CBD5E1", 
                    },
                    "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                        color: "#94A3B8",
                        fontWeight: 400,
                        fontSize: "16px",
                      },
                    "&:hover fieldset": {
                      border: "1px solid #CBD5E1",
                    },
                   
                  },
                }}
                placeholder="Chef Trainer, etc." name="title" 
              
                />
              </Grid>
            </Grid>

            {/* Profile Picture */}
            <Grid item xs={12} container alignItems="center" sx={{ marginTop: "24px" }}>
              <Grid item xs={4}>
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Profile Picture</InputLabel>
              </Grid>
              <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
              <Avatar
          sx={{
            bgcolor: "#f1e6c8",
            width: "104px",
            height: "104px",
            color: "#44041C",
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "32px",
            cursor: "pointer", 
          }}
          src={this.state.avatarImage} 
          onClick={this.handleAvatarClick} 
        >
          {!this.state.avatarImage && "JD"} 
        </Avatar>
        <input
          type="file"
          accept="image/*"
          ref={this.fileInputRef} 
          onChange={this.handleImageChange}
          style={{ display: "none" }} 
        />          </Grid>
            </Grid>

            {/* Description */}
            <Grid item xs={12} container alignItems="center" sx={{ marginTop: "24px" }}>
              <Grid item xs={4}>
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Description</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField fullWidth 
                data-test-id="description"
                value={description}
                onChange={(e) => this.setState({description:e.target.value})}
                placeholder="Share a bit about your work experience, cool projects you've completed, and your area of expertise." name="description" 
                sx={{
                  borderRadius: "8px", 
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    height: "118px",
                    "& fieldset": {
                      borderRadius: "8px",
                      border: "1px solid #CBD5E1", 
                    },
                    "& .MuiInputBase-input":{
                        fontFamily: "Poppins",
                      },
                    "&:hover fieldset": {
                      border: "1px solid #CBD5E1",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #CBD5E1", 
                    },
                    "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                        color: "#94A3B8",
                        fontWeight: 400,
                        fontSize: "16px",
                      },
                  },
                }}
                multiline rows={4} />
              </Grid>
            </Grid>

            {/* Timezone */}
            <Grid item xs={12} container alignItems="center" sx={{ marginTop: "24px" }}>
              <Grid item xs={4}>
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Timezone</InputLabel>
              </Grid>
              <Grid item xs={8} container spacing={1}>
                <Grid item xs={7}>
               
            <Select
              data-test-id="timeZone"
              value={timeZone}
              onChange={(e) => this.setState({ timeZone: e.target.value })}
              fullWidth
              displayEmpty
              name="timezone"
              placeholder="Timezone" 

              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <p style={{color: "#94A3B8"}}>Timezone</p>;
                }
    
                return selected;
              }}
              sx={{
                borderRadius: "8px",
                height: "56px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  height: "56px",
                  "& fieldset": {
                    borderRadius: "8px",
                    border: "1px solid #CBD5E1",
                  },
                  "&:hover fieldset": {
                    border: "1px solid #CBD5E1",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #CBD5E1",
                  },
                  "& .MuiSelect-select": {
                    fontFamily: "Poppins",
                    paddingRight: 0,
                    
                  },
                  

                },

                "& .MuiOutlinedInput-notchedOutline":{
                    borderColor:"#CBD5E1 !important",
                    borderWidth: "1px !important",
                  },
                "& .MuiSelect-icon": {
                  display: "none",
                },
                
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <UnfoldMoreIcon />
                  </IconButton>
                </InputAdornment>
              }
            >
            
              {this.state.TimeZoneValue.map((tz) => {
                const value = `${tz.timeZone} (${tz.gmtOffset})`
                return(
                  <MenuItem key={tz.gmtOffset} value={value} sx={{fontFamily: "Poppins",}}>
                  {tz.timeZone} ({tz.gmtOffset})
                </MenuItem>
              )})}
            </Select>

            
                </Grid>
               
                
              </Grid>
              <Divider sx={{ marginTop: "40px",width:"100%" }}  color="#CBD5E1" />

            </Grid>
            {/* Buttons */}
            <Grid item xs={12} container  style={{ marginTop: "24px",justifyContent:"end",gap:"20px" }}>
              <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                  Cancel
                </Button>
              </Box>
              <Box data-test-id="continue" sx={{ width: "108px", height: "44px", borderRadius: "8px", backgroundColor: "#44041C", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }} onClick={this.handleValidation}>
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
            
        </Paper>
      </Box>
      </>
      
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
