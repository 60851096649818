import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  TextField,
  List,
  ListItemAvatar,
  AvatarGroup,
  ListItemText,
  ListItem,
  Avatar,
  IconButton,
  Paper,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import { searchIcon,attachmentIcon, sendIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    },
});

const chatUserList = [
  { id: 1, name: "Elmer Laverty", message: "Haha oh man" },
  { id: 2, name: "Elmer Laverty", message: "Haha oh man" },
  { id: 3, name: "Elmer Laverty", message: "Haha oh man" },
  { id: 4, name: "Elmer Laverty", message: "Haha oh man" }
]
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";
import { borderRadius } from "react-select/src/theme";
import { Header } from "react-native-elements";
import zIndex from "@mui/material/styles/zIndex";
export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Container sx={webStyles.fullWidth}>
          <Box sx={webStyles.chatWrapper}>
            <Box sx={webStyles.sidebar}>
              <TextField
                data-test-id="search"
                variant="outlined"
                placeholder="Search"
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#667085",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Poppins",
                    paddingLeft: "10px",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    fontFamily: "Poppins",
                    width: "350px",
                    height: "44px",
                    backgroundColor: "#fff",
                    "&:hover fieldset": {
                      border: "1px solid #D6D3D1",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #D6D3D1",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <img src={searchIcon} alt="searchIcon" style={{
                      height: "20px", width: "20px", color: "#667085"
                    }} />
                  ),
                }}
              />

              <List sx={{ mt: 2 }}>
                {chatUserList.map((userList) => (
                  <ListItem sx={webStyles.siebarItem}>
                    <ListItemAvatar>
                      <AvatarGroup>{userList.name.charAt(0)}</AvatarGroup>
                    </ListItemAvatar>
                    <ListItemText
                      primary={userList.name}
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            {userList.message}
                          </Typography>
                          <span style={{
                            position: "absolute",
                            right: "0",
                            top: "0"
                          }}>12h </span>
                        </>
                      }
                    />
                  </ListItem>
                )
                )}

              </List>
            </Box>
            <Box sx={webStyles.chatWindow}>
              <Box sx={webStyles.chatWindowHeader}>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Avatar sx={{ mr: 2 }}></Avatar>
                  <Box>
                  <Typography variant="h6" style={{
                    fontFamily:"Poppins",
                    fontWeight:"600",
                    fontSize:"20px"
                    }}> 
                    Florencio Dorrance </Typography>
                    <Box sx={{display: "flex", alignItems:"center", gap:"8px"}}> 
                      <span style={webStyles.onindigation}></span> 
                      <Typography style={{
                    fontFamily:"Poppins",
                    fontSize:"12px",
                    }}> Online</Typography> 
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={webStyles.chatWindowBody}>
                {this.state.messages.map((msg) => (
                  <Paper
                    key={msg.id}
                    sx={{
                      padding: "8px 12px",
                      marginBottom: "8px",
                      maxWidth: "40%",
                      boxShadow:"none",
                      border: "1px solid #E2E8F0",
                      alignSelf: msg.sender === "me" ? "flex-end" : "flex-start",
                      bgcolor: msg.sender === "me" ? "#44041C" : "#fff",
                      color: msg.sender === "me" ? "#fff" : "#000",
                      borderRadius: "12px",
                    }}
                  >
                    <Typography>{msg.text}</Typography>
                  </Paper>
                ))}
              </Box>
              <Box sx={webStyles.chatWindowFooter}>
                <Box sx={{display:'flex', alignItems: 'center' }}>
                  <Button sx={webStyles.customeFileUpload}>
                    <input type="file" id="myFile" name="filename"/>
                    <img
                        src={attachmentIcon}
                        alt="attachment"
                        style={{
                          filter: "invert(47%) sepia(12%) saturate(343%) hue-rotate(178deg) brightness(90%) contrast(89%)" // Use filter for color changes
                        }}
                      />
                  </Button>
                  <Box
                    display="flex"
                    alignItems="center"
                    p={1}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      multiline
                      placeholder="Type a message"
                      value={this.state.inputmessage}
                      onChange={(e) => this.setState({inputmessage:e.target.value})}
                      onKeyDown={this.handleSendMessageOnEnter}
                      data-test-id="handleSendMessageOnEnter"
                    />
                    <IconButton color="primary" onClick={this.handleSendMessage} data-test-id="handleSendMessage">
                    <img
                      src={sendIcon}
                      alt="sendIcon"
                      style={{
                        height: "20px",
                        width: "20px",
                        filter: "invert(47%) sepia(12%) saturate(343%) hue-rotate(178deg) brightness(90%) contrast(89%)" // Use filter for color changes
                      }}
                    />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </>
    )
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles = {
  fullWidth: {
    width: "100%",
    minWidth: "100%",
    padding: "0 !important",
  },

  chatWrapper: {
    width: "100%",
    display: "flex",
  },

  sidebar: {
    width: "350px",
    position: "fixed",
    left: '0',
    height: '100%',
    backgroundColor: "#fff",
    boxShadow: '3px 0 5px rgba(0, 0, 0, 0.1)',
    padding: '20px',

    "& .MuiListItemAvatar-root":{
      width:"48px",
      height: "48px",
      backgroundColor:"#ccc",
      borderRadius:"12px",
      marginRight:"16px"
    },

    "& .MuiListItemText-primary":{
      fontFamily:"Poppins",
      fontWeight:"600",
      fontSize:"14px"
    },
    "& .MuiTypography-body2":{
      fontFamily:"Poppins",
      fontWeight:"600",
      fontSize:"12px",
      color:"rgba(0,0,0,0.40)"
    }
  },

  siebarItem: {
    border: '1px solid #E2E8F0',
    borderRadius: "8px",
    marginBottom: '20px',
    "& .MuiListItemText-root": {
      position: "relative",
    },
  },

  chatWindow: {
    width: "calc(100% - 390px)",
    backgroundColor: "#FAFAF9",
    marginLeft: '390px',
    height: "100vh"
  },

  chatWindowHeader: {
    borderBottom: '1px solid rgba(0,0,0,0.08)',
    padding: "15px 30px",
    position:'fixed',
    width: "calc(100% - 390px)",
    top: "0",
    backgroundColor:"#fff"
  },

  onindigation:{
    backgroundColor:"#68D391",
    width:'10px',
    height:'10px',
    display: 'inline-block',
    borderRadius:'10px',
  },

  chatWindowFooter:{
    padding: "10px",
    position:'fixed',
    width: "calc(100% - 390px)",
    right: "-24px",
    backgroundColor:"#fff",
    bottom: "0",
    "& .MuiBox-root":{
      width :"calc(100% - 25px)",
      position: "relative",
      "& .MuiIconButton-root ":{
         position: "absolute",
         right: "12px",
      }
    }
  },

  customeFileUpload:{
     "& input[type='file']":{
      opacity:"0",
      cursor:"pointer",
      width: "40px",
      height: "40px !important",
      position: "absolute",
      zIndex:'99'
     }
  },

  chatWindowBody:{
    margin:"130px 0",
    padding: "0 30px",
    display:"flex",
    flexDirection: "column",
    overFlowY:"scroll",
    minHeight:"50vh"
  }
};
// Customizable Area End
