import React from "react";
// Customizable Area Start
import {
  Paper,
  Stepper, Divider,
  Step, StepConnector,
  StepLabel, Box, styled,
  TextField, Button, InputLabel, Typography, Grid
} from "@mui/material";
import { ToastContainer } from 'react-toastify';
import {
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';
import Header from '../../../blocks/landingpage/src/Header.web'
// Customizable Area End
// Customizable Area Start
import CustomisableSellerExperienceController, {
  Props,
} from "./CustomisableSellerExperienceController";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme { }
}
const CustomConnector = styled(StepConnector)(() => ({
  "&.MuiStepConnector-root": {
    top: 10,
  },
  "& .MuiStepConnector-line": {
    borderColor: "#D3D3D3",
    borderTopWidth: 2,
  },
  "&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line": {
    
    borderColor: "#4A192C",
  },
}));
const CustomStepLabel = styled(StepLabel)(() => ({
  "& .MuiStepLabel-label": {
    color: "#D3D3D3",
    fontSize: "16px", 
    fontFamily: "Poppins, sans-serif",
    transition: "color 0.3s ease-in-out",
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: "#4A192C",
    fontWeight: 600,
  },
}));
const experienceOptions = [
    { label: "None", value: "None" },
    { label: "0 - 4 years", value: "0-4" },
    { label: "5 - 9 years", value: "5-9" },
    { label: "10+ years", value: "10+" },
  ];
// Customizable Area End

export default class CustomisableSellerExperience extends CustomisableSellerExperienceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CustomStepIcon = ({ active, completed }: { active: boolean; completed: boolean }) => {

    const borderColor = active || completed ? "#4A192C" : "#CFD6DC";
    
    const backgroundColor = completed || active ? "#4A192C" : "transparent";
  
    return (
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          backgroundColor,
          border: `2px solid ${borderColor}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {completed ? (
          <CheckIcon style={{ color: "#FFFFFF", fontSize: "18px" }} />
        ) : (
          active && (
            <div
              style={{
                width: "14px",
                height: "14px",
                borderRadius: "50%",
                backgroundColor: "#4A192C",
                position: "absolute",
                border: "5px solid #fff",
              }}
            />
          )
        )}
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {workHistory,resumeUploaded,selectedCookingExperience,selectedPrivateChef,selectedCateringServices,selectedExecutiveChef,selectedCulinaryContent,selectedFranchiseConsulting,selectedRestaurantConsulting,selectedSousChef} = this.state;
    const steps = ["Personal Info", "Experience", "Payment Info"];
    return (
      // Customizable Area Start
      <>
      <Header navigation={undefined} id={""} />
      <Box
        sx={{
          padding: { xs: "30px 20px 46px 20px", sm: "30px 20px 46px 20px", md: "30px 214px 46px 214px", lg: "30px 214px 46px 214px" }
        }}
      >
        <Paper elevation={0} style={{boxShadow: "0px 0px 10px rgb(189 179 179 / 40%)", padding: 20, marginTop: 20 }}>
            <Stepper 
            activeStep={this.getActiveStep()} alternativeLabel 
            connector={<CustomConnector />}>
             
             {steps.map((label, index) => (
               
               <Step key={index} 
               
               completed={index < this.getActiveStep()}>
                 
                  <CustomStepLabel 
                  StepIconComponent={this.CustomStepIcon}>
                    {label}
                  </CustomStepLabel>
                
                </Step>
              
              ))}
            </Stepper>
          <Typography sx={{ marginTop: "40px", fontFamily: "Poppins", fontWeight: 700, fontSize: "24px", color: "#000000" }}>
            Experience
          </Typography>
          <ToastContainer />
          <Divider sx={{ marginTop: "40px", width: "100%" }} color="#CBD5E1" />
          <Grid container spacing={2} sx={{ marginTop: "40px" }}>

            {/* Description */}
            <Grid item xs={12} container alignItems="start">
              <Grid item xs={4} marginTop="15px">
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Work history</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField fullWidth 
                  data-test-id="workHistory"
                  value={workHistory}

                  sx={{
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      height: "118px",
                      "& .MuiInputBase-input":{
                        fontFamily: "Poppins",
                      },
                      "& fieldset": {
                        borderRadius: "8px",
                        border: "1px solid #CBD5E1",
                      },
                      
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                        color: "#94A3B8",
                        fontWeight: 400,
                        fontSize: "16px",
                      },
                      "&:hover fieldset": {
                        border: "1px solid #CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid #CBD5E1",
                      },
                    },
                  }}
                  onChange={(e) => this.setState({workHistory:e.target.value})}
                  placeholder="Tell us about your work history or upload your resume" name="description"
                 
                  multiline rows={4} />
              </Grid>
            </Grid>

            {/* Resume Upload */}
            <Grid item xs={12} container alignItems="center" sx={{ marginTop: "24px" }}>
              
              <Grid item xs={4}>
                
                <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>Or upload your resume</InputLabel>
             
              </Grid>
              <Grid item xs={8} container spacing={1}>
                <Grid item xs={6} >
                  <Box sx={{
                  padding:"8px",
                  textAlign: "center", display: "flex",
                  justifyContent: "center", width: "169px", height: "40px", border: "1px solid #44041C", borderRadius: "8px"
                }}>
                  <Button sx={{
                    fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C", textTransform: "none", display: "flex", alignItems: "center",
                    width: "169px",
                    justifyContent: "center"
                  }} onClick={this.handleButtonClick} >
                    Upload Resume
                    <input
                      type="file"
                      ref={this.fileInputRef}
                      style={{ display: "none",fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}
                      onChange={this.handleFileChange}
                    />
                  </Button>
                  </Box>
                </Grid>
              </Grid>
              {resumeUploaded && (
                <Grid item xs={12} sx={{ marginTop: "16px" }}>
                  <Typography variant="body2" color="textSecondary">
                    Uploaded file: {resumeUploaded.name}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography style={styles.experienceText}>How many years of professional culinary experience do you have in:</Typography>
            </Grid>

            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={styles.experienceText}>Cooking Classes</Typography>
                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      data-test-id="cooking"
                      onClick={() => this.handleCookingSelect(option.value)}
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedCookingExperience === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Private Chef</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      data-test-id="privateChef"
                      onClick={() => this.handleSelectPrivateChef(option.value)}
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedPrivateChef === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Executive Chef</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => this.handleExecutiveChef(option.value)}
                      data-test-id="executiveChef"
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedExecutiveChef === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Sous Chef</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => this.handleSousChef(option.value)}
                      data-test-id="sauschef"
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedSousChef === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Restaurant Consulting</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => this.handleRestaurantConsulting(option.value)}
                      data-test-id="restaurant"
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedRestaurantConsulting === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Franchise Consulting</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => this.handleFranchiseConsulting(option.value)}
                      data-test-id="franchise"
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedFranchiseConsulting === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Catering Services</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => this.handleCateringServices(option.value)}
                      data-test-id="cateringService"
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedCateringServices === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={12} sm={7}>
                <Typography style={{...styles.experienceText,marginTop:"40px"}}>Culinary Content Creator</Typography>

                <Grid container spacing={1} justifyContent="center">
                  {experienceOptions.map((option) => (
                    <Grid
                      key={option.value}
                      item
                      xs={6}
                      sm={3}
                      onClick={() => this.handleCulinaryContent(option.value)}
                      data-test-id="culinary"
                    >
                      <Typography sx={{
                    ...styles.yearText,
                    ...(selectedCulinaryContent === option.value ? styles.selectedOption : {}),
                  }}>{option.label}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "40px", width: "100%" }} color="#CBD5E1" />


            {/* Buttons */}
            <Grid item xs={12} container style={{ marginTop: "24px", justifyContent: "end", gap: "20px" }}>
              <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                  Cancel
                </Button>
              </Box>
              <Box onClick={this.handleNavigateToPayment} data-test-id="continue" sx={{ width: "108px", height: "44px", borderRadius: "8px", backgroundColor: "#44041C", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button data-test-id="continue" sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>

        </Paper>
      </Box>
      </>
      
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  selectedOption: {
    backgroundColor: "#44041C",
    color: "#FFFFFF",
  },
  experienceText: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: "16px",
    color: "#64748B",marginBottom:"16px"
  },
  noneText: {
    fontFamily: "Poppins !important",
    fontWeight: 400,
    fontSize: "16px",
    color: "#44041CCC",
    border: "1px solid #44041C4D",
    textAlign: "center" as "center",
    width: "100%",
    height: "34px",
    display: "flex",
    justifyContent: "center" as "center",
    paddingTop: "10px",
    alignItem: "center",

    borderRadius: "8px"
  },
  yearText: {
    fontFamily: "Poppins !important",
    fontWeight: 400,
    fontSize: "16px",
    color: "#44041CCC",
    border: "1px solid #44041C4D",
    textAlign: "center" as "center",
    height: "34px",
    display: "flex",
    justifyContent: "center" as "center",
    alignItem: "center",
    paddingTop: "10px",
    borderRadius: "8px",
    cursor: "pointer",
  },
}
// Customizable Area End
