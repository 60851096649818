import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface SubCategory {
  id: number;
  name: string;
  created_at: string; // You can use Date if you want to convert it later
  updated_at: string; // You can use Date if you want to convert it later
  parent_id: number | null;
  rank: number | null;
  category_id: number;
}
interface CategoryAttributes {
  id: number;
  name: string;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string; 
  updated_at: string; 
  sub_categories: SubCategory[];
  selected_sub_categories: any; 
}

interface Category {
  data: {
  id: string;
  type: string;
  attributes: CategoryAttributes;
  }
  
}

interface ServiceImage {
  id: number;
  url: string;
}

interface SubCategory {
  id: number;
  name: string;
  created_at: string; // You can use Date if you want to convert it later
  updated_at: string; // You can use Date if you want to convert it later
  parent_id: number | null;
  rank: number | null;
  category_id: number ;
}
interface ServiceAttributes {
  title: string;
  description: string;
  price: number | null;
  duration: number | null;
  best_seller: boolean;
  sub_category_id: number;
  service_image: ServiceImage;
  created_by: string;
  sub_category: SubCategory;
}

interface Service {
  id: string;
  type: string;
  attributes: ServiceAttributes;
}
interface PosterImage {
  id: number;
  url: string;
}

interface PosterAttributes {
  title: string;
  description: string;
  poster_images: PosterImage[];
}

interface Poster {
  id: string;
  type: string;
  attributes: PosterAttributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null,
  openMenu:number | null,
  categoryData:Category[],
  serviceCardsData: Service[],
  serviceCardsError:string;
  posterData:Poster[],
  currentPage: number,
  loading: boolean,
  searchValue:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  landingPageCategoryApiCallId: string = "";
  serviceApiCallId:string = "";
  searchApiCallId:string = "";
  posterApiCallId:string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      anchorEl: null,
      openMenu:null,
      categoryData:[],
      serviceCardsData:[],
      serviceCardsError:"",
      posterData:[],
      currentPage: 1,
      loading: false,
      searchValue:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  serviceApi = (responseJson:any) => {
    if (responseJson && responseJson.data) {
      this.setState((prevState) => ({
        serviceCardsData: this.state.searchValue ? responseJson?.data : [...prevState.serviceCardsData, ...responseJson?.data],
        serviceCardsError: "",
        loading: false,
    }), () => {
        this.setState({ currentPage: responseJson?.meta?.current_page });
    });
  } else {
    this.setState({ 
        serviceCardsData: [],
        serviceCardsError: "No services yet", 
        loading: false
    });
}
  }
  
  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.landingPageCategoryApiCallId) {
          this.setState({categoryData:responseJson?.data})
        }
        if(apiRequestCallId === this.serviceApiCallId){
          this.serviceApi(responseJson)
        } 
        if(apiRequestCallId === this.posterApiCallId){
          this.setState({posterData :responseJson?.data })
        }
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  handleNavigtaionToCategory = () => {
    this.props.navigation.navigate('Categoriessubcategories')
  }

  navigationTwoCategory = (categoryId: number) =>{
    const msgNavigation = new Message(getName(MessageEnum.NavigationMessage));
    msgNavigation.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msgNavigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), categoryId);
    msgNavigation.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgNavigation);
  }
  async componentDidMount() {
    this.landingPageCategoryApi();
    this.fetchServiceCards();
    this.posterApi()
  }

  landingPageCategoryApi = () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      // token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.landingPageCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/categories/fetch_associate_sub_categories"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  posterApi = () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      // token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.posterApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landingpage2/posters"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  fetchServiceCards  = async () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      // token: token,
    };
    const apiEndPoint = this.state.searchValue ? 
    `bx_block_landingpage2/services/search_service?title=${(this.state.searchValue)}`:
     `bx_block_landingpage2/services?per_page=8&page=${this.state.currentPage}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSearch = () => {
    this.setState({currentPage:0} , () => this.fetchServiceCards()) 
  };
  handleSubCategory = (index:number,event:any) => {
    this.setState({
      openMenu : index,
      anchorEl: event.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({ openMenu: null }); 
  };

  handleViewAll = () => {
    this.setState(
      (prevState) => ({ currentPage: prevState.currentPage + 1,searchValue:"" }),
      () => {
        this.fetchServiceCards();
      }
    );
  };

 goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  // Customizable Area End
}
