import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchValue:string
  token:string;
  navigationItem:any,
  landingPageNavigation:any,
  listingPagePath:any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      searchValue:"",
      token:"",
      navigationItem : ['/', '/LandingPageWeb','/WebAppointments', '/WebCategoriessubcategories','/CustomisableSellerExperience','/CustomisableUserProfiles','/CustomisableSellerPayment','/SocialMediaAccountRegistrationWeb'],
      landingPageNavigation:['/', '/LandingPageWeb'],
      listingPagePath:["/LocalListingAds"]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    const loginApiToken = await getStorageData("loginToken")
    this.setState({token:loginApiToken})
    // Customizable Area End
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  handleSearchValueChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({ searchValue: e.target.value })
  }
  
  handleUserProfileNavigation = () => {
    window.location.href = '/SocialMediaAccountRegistrationWeb';
  }
  handleNavigateToLandingPage = () => {
    window.location.href = '/';
  }
  handleNavigation = () => {
    window.location.href = '/EmailAccountLoginBlock';
  }
  handleNavigationToSignUp = () => {
    
    localStorage.setItem('become_a_seller', 'true');
    window.location.href = '/EmailAccountRegistrationWeb';
  }
  // Customizable Area End
}
