import React from "react";
// Customizable Area Start
import {
  Paper,
  Stepper, Divider,
  Step, StepConnector,
  StepLabel, Box, styled,
  TextField, Button, InputLabel, Typography, Grid
} from "@mui/material";
import { ToastContainer } from 'react-toastify';
import "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';
import Header from '../../../blocks/landingpage/src/Header.web'
// Customizable Area End
// Customizable Area Start
import CustomisableSellerPaymentController ,{Props} from './CustomisableSellerPaymentController'


const CustomStepLabel = styled(StepLabel)(() => ({
  "& .MuiStepLabel-label": {
    color: "#D3D3D3",
    fontSize: "16px", 
    fontFamily: "Poppins, sans-serif",
    transition: "color 0.3s ease-in-out",
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: "#4A192C",
    fontWeight: 600,
  },
  "&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line": {
    
    borderColor: "#4A192C",
  
  },

}));
const CustomConnector = styled(StepConnector)(() => ({
  "&.MuiStepConnector-root": {
    top: 10,
  },
  "& .MuiStepConnector-line": {
    borderColor: "#D3D3D3",
    borderTopWidth: 2,
  },
}));


// Customizable Area End

export default class CustomisableSellerPayment extends CustomisableSellerPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CustomStepIcon = 
  ({ active, completed }: { active: boolean; completed: boolean }) =>
    
    {
  
    const backgroundColor = completed || active ? "#4A192C" : "transparent";
   
   
   
    const borderColor = active || completed ? "#4A192C" : "#CFD6DC";
  
    return (
      <div
        style={{
          width: "24px",
          borderRadius: "50%",
          backgroundColor,
          border: `2px solid ${borderColor}`,
          display: "flex",
          height: "24px",
          
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {completed ? 
        
        (
          <CheckIcon 
          style={{ 
            color: "#FFFFFF", 
            fontSize: "18px" 
          }} />
        
        ) : (
          active && (
            <div
              style={{
                width: "14px",
                height: "14px",
              
                backgroundColor: "#4A192C",
                position: "absolute",
  
                border: "5px solid #fff",
                borderRadius: "50%",
               
              
              }}
            
            />
          )
        )}
      </div>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    
    const steps = ["Personal Info", "Experience", "Payment Info"];
    
    
    const {accountName,ibanValue} = this.state;

    return (
      // Customizable Area Start
      <><Header navigation={undefined} id={""} />
      <Box
        sx={{
          padding: { xs: "30px 20px 46px 20px", sm: "30px 20px 46px 20px", md: "30px 214px 46px 214px", lg: "30px 214px 46px 214px" }
        }}
      >
        <Paper elevation={0} style={{boxShadow: "0px 0px 10px rgb(189 179 179 / 40%)", padding: 20, marginTop: 20 }}>
        <Stepper activeStep={this.getActiveStep()} alternativeLabel connector={<CustomConnector />}>
  {steps.map((label, index) => (
    <Step key={index} completed={index < this.getActiveStep()}>
      <CustomStepLabel StepIconComponent={this.CustomStepIcon}>
        {label}
      </CustomStepLabel>
    </Step>
  ))}
</Stepper>
          <Typography sx={{ marginTop: "40px", fontFamily: "Poppins", fontWeight: 700, fontSize: "24px", color: "#000000" }}>
           Payment info 
          </Typography>
          <ToastContainer />
          <Divider sx={{ marginTop: "40px", width: "100%" }} color="#CBD5E1" />
                <Grid container spacing={2}>
                <Grid item xs={12}>
                <InputLabel style={{ ...styles.inputLabelText, marginBottom: "-10px" }}>Account Name</InputLabel>
                </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            data-test-id="accountName"
                            value={accountName}
                            onChange={(e) => this.setState({accountName:e.target.value})}
                            variant="outlined"
                            sx={{
                                borderRadius: "8px", 
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                  height: "56px",
                                  "& fieldset": {
                                    borderRadius: "8px",
                                    border: "1px solid #CBD5E1", 
                                  },
                                  "& .MuiInputBase-input": {
                                    fontFamily: "Poppins",
                                  },
                                  "&:hover fieldset": {
                                    border: "1px solid #CBD5E1",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "1px solid #CBD5E1", 
                                  },
                                  "& .MuiInputBase-input::placeholder": {
                                      fontFamily: "Poppins, sans-serif",
                                      color: "#94A3B8",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                    },
                                },
                              }}
                        />
                    </Grid>

                    {/* IBAN Field */}
                    <Grid item xs={12}>
                <InputLabel style={{ ...styles.inputLabelText, marginBottom: "-10px" }}>IBAN</InputLabel>
                </Grid>
                    <Grid item xs={6}>
                        <TextField
                            data-test-id="ibanValue"
                            value={ibanValue}
                            onChange={(e) => this.setState({ibanValue : e.target.value})}
                            fullWidth
                            variant="outlined"
                            sx={{
                                borderRadius: "8px", 
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                  height: "56px",
                                  "& fieldset": {
                                    borderRadius: "8px",
                                    border: "1px solid #CBD5E1", 
                                  },
                                  "& .MuiInputBase-input": {
                                    fontFamily: "Poppins",
                                  },
                                  "&:hover fieldset": {
                                    border: "1px solid #CBD5E1",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "1px solid #CBD5E1", 
                                  },
                                  "& .MuiInputBase-input::placeholder": {
                                      fontFamily: "Poppins",
                                      color: "#94A3B8",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                    },
                                },
                              }}
                        />
                    </Grid>
                    <Divider sx={{ marginTop: "40px", width: "100%" }} color="#CBD5E1" />

                    {/* Buttons */}
                    <Grid item xs={12} container style={{ marginTop: "24px", justifyContent: "end", gap: "20px" }}>
                        <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                                Cancel
                            </Button>
                        </Box>
                        <Box  sx={{ width: "73px", height: "44px", borderRadius: "8px", backgroundColor: "#44041C", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Button data-test-id="continue" onClick={this.sellerProfileApi} sx={{ textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

        </Paper>
      </Box>
      </>
      
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
    container: {
      width: "100%",
      height: "100%",
    },
    inputLabelText: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: "16px",
      marginTop: "40px",
      color: "#64748B",
      fontWeight: 700,
    }
  }
// Customizable Area End
