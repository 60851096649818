// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Search = require("../assets/Search.png");
export const close = require("../assets/close.png");
export const check = require("../assets/check.png");
export const check_img = require("../assets/check.png");
export const close_img = require("../assets/close.png");
export const left_img = require("../assets/left.png");
export const pdf_img = require("../assets/pdf.png");
export const search_img = require("../assets/Search.png");
export const searchIcon = require("../assets/searchIcon.png");
export const foodItems = require("../assets/food.png");
export const foodImg = require("../assets/foodImg.png");
export const profileImg = require("../assets/profileImg.png");
export const service = require("../assets/service.png");

// Customizable Area End
