// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

export interface IListItem {
  id: string,
  type: string,
  attributes: {
    filename: null,
    file: {
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  indexArr: IListItem[];
  videoArr: IListItem[];
  audioArr: IListItem[];
  documentsArr: IListItem[];
  imageAll: boolean;
  audioAll: boolean;
  videoAll: boolean;
  documentsAll: boolean;
  tabvalue: number;
  selectedRating: number | null;
  token:string;
  portfolioData: any;
  serviceData:any;
  searchCategory : string;
}

interface RatingDataType {
  stars: number;
  count: number;
  percentage: number;
}

interface SS {
  id: any;
}

export const ratingsData: RatingDataType[] = [
  { stars: 5, count: 20, percentage: 80 },
  { stars: 4, count: 35, percentage: 70 },
  { stars: 3, count: 8, percentage: 40 },
  { stars: 2, count: 5, percentage: 30 },
  { stars: 1, count: 3, percentage: 20 },
];

interface Review {
  name: string;
  date: string;
  avatar: string;
  rating: number;
  comment: string;
}

export const reviews: Review[] = [
  {
    name: "KaiB",
    date: "22 Jul",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    rating: 5,
    comment:
      "KaiB was amazing with our cats!! 🌟🌟🌟 This was our first time using a pet-sitting service, so we were naturally quite anxious. We took a chance on Kai and completely lucked out!",
  },
  {
    name: "John Doe",
    date: "22 Jul",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
    rating: 4,
    comment:
      "KaiB was phenomenal with our dog, Max! We were first-time users of a pet-sitting service and were quite nervous. Kai's professionalism and warmth immediately put us at ease.",
  },
];

export default class PortfolioManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  indexAPICallID: string = "";
  videosAPICallID: string = "";
  audiosAPICallID: string = "";
  documentsAPICallID: string = "";
  sellerPortfolioCallID:string="";
  seller_id:string=""
  categoryCardsApiCallId: string="";
  porfileDataAPICallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.seller_id="";

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      indexArr: [],
      videoArr: [],
      audioArr: [],
      documentsArr: [],
      imageAll: false,
      audioAll: false,
      videoAll: false,
      documentsAll: false,
      tabvalue: 0,
      selectedRating: null,
      token:"",
      portfolioData: [],
      serviceData:[],
      searchCategory: "",
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if (apiRequestCallId1 && responseJson1) {
      if (apiRequestCallId1 === this.indexAPICallID && !responseJson1.errors) {
        this.setState({
          indexArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.videosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          videoArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.audiosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          audioArr: responseJson1.data,
        });
      }
      if (
        apiRequestCallId1 === this.documentsAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          documentsArr: responseJson1.data,
        });
      }
      if(apiRequestCallId1 === this.sellerPortfolioCallID && !responseJson1.errors) {
         this.setState({
          portfolioData: responseJson1.data,
          serviceData:responseJson1.data
         })
      }

      if (apiRequestCallId1 === this.categoryCardsApiCallId ) {
        if(!responseJson1.errors){
        this.setState({ serviceData: responseJson1.data });
        }
        else {
          this.setState({ serviceData: [] });
        }
       }
    }
  }

  

  // web events

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabvalue: newValue });
  };

  handleFilterClick = (rating: number) => {
    this.setState({ selectedRating: rating });

  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  touchableViewContainer = {
    onPress: () => this.hideKeyboard(),
  };

  videoSeeAllContainer = {
    onPress: () => {
      this.setState({ videoAll: true });
      this.props.navigation.navigate("SelectedFile", {
        type: "video",
      });
    },
  };

  imageSeeAllContainer = {
    onPress: () => {
      this.setState({ imageAll: true });
      this.props.navigation.navigate("SelectedFile", {
        type: "image",
      });
    },
  };

  audioSeeAllContainer = {
    onPress: () => {
      this.setState({ audioAll: true })
      this.props.navigation.navigate("SelectedFile", {
        type: "audio",
      });
    },
  };

  documentSeeAllContainer = {
    onPress: () => {
      this.setState({ documentsAll: true });
      this.props.navigation.navigate("SelectedFile", {
        type: "application",
      });
    },
  };

  imageAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.indexAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=image`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  audioAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.audiosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=audio`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  documentsAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.documentsAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=application`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  videosAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.videosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=video`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSellerPortfolio = async () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: this.state.token,
    };
    const apiEndPoint = `bx_block_portfolio_management/items/view_portfolio?seller_id=${this.seller_id}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sellerPortfolioCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchCategoryCards  = async () => {
    const { searchCategory } = this.state
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      // token: token,
    };
    let apiEndPoint;

    if (searchCategory) {
      apiEndPoint = `/bx_block_landingpage2/services/search_service?title=${searchCategory}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryCardsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    } 
    else {
  this.getSellerPortfolio() 
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.seller_id=this.props.navigation.getParam('id')
    const loginApiToken = await getStorageData("loginToken")
        this.setState({token:loginApiToken})
    // this.imageAPI();
    // this.videosAPI();
    // this.audioAPI();
    // this.documentsAPI();
    this.getSellerPortfolio();
  }
}

// Customizable Area End
