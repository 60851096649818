import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import React from "react";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCookingExperience:string;
  selectedPrivateChef:string;
  selectedExecutiveChef:string;
  
  
  
  
  selectedSousChef:string;
  selectedRestaurantConsulting:string;
  
  selectedFranchiseConsulting:string;
  lastName: string;
  fullName: string;
  avatarImage: any,
  timeZone:string;
  
  
  title:string;
  email: string;
  selectedCateringServices:string;
  selectedCulinaryContent:string;
  
  phoneNumber: string;
  city: string;
  address: string;
  countryOpen: boolean;
  
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  
  
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  
  facebook: string;
  youtube: string;
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;
  workHistory:string;
  txtInputValue: string;
  txtSavedValue: string;
  firstName:string;
  last_name:string;
  description:string;
  first_name: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  resumeUploaded:any;
  userProfileData:any;
  activeStep:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableSellerExperienceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  fileInputRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep:this.getActiveStep(),
      firstName:"",
      userProfileData:null,
      last_name:"",
      selectedCookingExperience:"",
      selectedPrivateChef:"",
      selectedExecutiveChef:"",
      selectedSousChef:"",
      selectedRestaurantConsulting:"",
      selectedFranchiseConsulting:"",
      selectedCateringServices:"",
      selectedCulinaryContent:"",
      avatarImage: null,
      title:"",
      timeZone:"",
      description:"",
      resumeUploaded:null,
      profileImageUrl: "",
      workHistory:"",

     
      address: "",
      
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      city: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,



      youtube: "",
      instagram: "",
      passwordHelperText: "",
      enablePasswordField: true,


      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      userAge: 0,
      phoneNumber: "",
      
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
     

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,


      saveButtonDisable: false

      // Customizable Area End
    };
    // Customizable Area Start
    
    
    this.fileInputRef = React.createRef();
    
    // Customizable Area End
    
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      if (data) {
        this.setState({userProfileData:data})
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getActiveStep = () => { 
    const path = window.location.pathname;
    if (path.includes("CustomisableSellerPayment")) return 2;
    if (path.includes("CustomisableSellerExperience")) return 1;
    return 0;
  };
  handleNavigateToPayment = () => {
    const { userProfileData,workHistory,resumeUploaded,selectedCookingExperience,selectedPrivateChef,selectedExecutiveChef,selectedSousChef,selectedCateringServices,selectedCulinaryContent,selectedFranchiseConsulting,selectedRestaurantConsulting} = this.state;
  
    if (!workHistory) {
      toast.error("Please fill Work History");
      return;
    }
  
    if (!resumeUploaded) {
      toast.error("Please upload a Resume");
      return;
    }
    if(!selectedCookingExperience || !selectedPrivateChef || !selectedExecutiveChef  || !selectedSousChef || !selectedCateringServices || !selectedCulinaryContent || !selectedFranchiseConsulting || !selectedRestaurantConsulting)
    {
      toast.error("All Experience Required");
      return;
    }
    const wholeData = {
      workHistory,
      resumeUploaded,
      selectedCookingExperience,
      selectedPrivateChef,
      selectedExecutiveChef,
      selectedSousChef,
      selectedCateringServices,
      selectedCulinaryContent,
      selectedFranchiseConsulting,
      selectedRestaurantConsulting,
      userProfileData, 
    };
      this.navigationTwo(wholeData)
  };
 
  handleSelectPrivateChef = (experience:string) => {
    this.setState({ selectedPrivateChef: this.state.selectedPrivateChef === experience ? "" : experience });
  };

  
  handleExecutiveChef = (experience:string) => {
    this.setState({ selectedExecutiveChef: this.state.selectedExecutiveChef === experience ? "" : experience });
  }
  navigationTwo = (data:any) =>{
    const msgNavigation = new Message(getName(MessageEnum.NavigationMessage));
    msgNavigation.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableSellerPayment");
    msgNavigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    msgNavigation.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgNavigation);
  }
  handleCookingSelect = (experience:string) => {
    this.setState({
      selectedCookingExperience: this.state.selectedCookingExperience === experience ? "" : experience,
    });
  };
 
  handleCateringServices = (experience:string) => {
    this.setState({ selectedCateringServices: this.state.selectedCateringServices === experience ? "" : experience });
  }
  
  handleFranchiseConsulting = (experience:string) => {
    this.setState({ selectedFranchiseConsulting: this.state.selectedFranchiseConsulting === experience ? "" : experience });
  }
 
  handleRestaurantConsulting = (experience:string) => {
    this.setState({ selectedRestaurantConsulting: this.state.selectedRestaurantConsulting === experience ? "" : experience });
  }
  
  handleCulinaryContent = (experience:string) => {
    this.setState({ selectedCulinaryContent: this.state.selectedCulinaryContent === experience ? "" : experience });
  }
  handleSousChef = (experience:string) => {
    this.setState({ selectedSousChef: this.state.selectedSousChef === experience ? "" : experience });
  }
 
  handleFileChange = (event:any) => {
    const file = event.target.files[0];  
    this.setState({ resumeUploaded: file }); 
  };

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };
  // Customizable Area End
}
