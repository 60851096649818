import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  IconButton,
  
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import ContactusController, { Props } from "./ContactusController";
import { rightImage } from '../src/assets';
import { borderRadius } from "react-select/src/theme";
import { BorderColor, Height } from "@mui/icons-material";
import { color } from "react-native-elements/dist/helpers";
import { min } from "lodash";


// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
       <Box
        sx={{
          alignItems: { xs: "center", md: "flex-start" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
        }}
        >
          {/* Left Section */}
        <Box
          style={styles.cooksyImgBox}
          sx={{
            width: { xs: "100%", md: "auto" },
          }}>
          <Box
            sx={{
              width: { xs: "100%", sm: "400px", md: "500px" }, marginBottom: "32px"
            }}>
            <Typography sx={styles.contactusText}>Contact us</Typography>
            <Typography sx={styles.captionText}>
              Fill in the form below, and we will get back to you as soon as possible!
            </Typography>

            <Box sx={styles.customeClass}>
              <Box 
                sx={{
                  width: {
                    xs: "100%", sm: "400px", md: "500px",
                    borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#CBD5E1",
                      },
                    },
                  },
                  height: "82px", marginBottom: "16px"
                }}
              >
                <InputLabel sx={styles.inputLabelText}>Name <span style={{ color: "red" }}>*</span></InputLabel>
                <TextField
                   value={this.state.contactusFormData.name}
                   error={Boolean(this.state.nameError)}
                  helperText={this.state.nameError}
                  data-testid="nameField" type="Text" placeholder="John Doe" fullWidth
                  sx={styles.inputField}
                  onChange={(e) =>
                    this.setState({
                      contactusFormData: {
                        ...this.state.contactusFormData,
                        name: e.target.value,
                      },
                      nameError:''
                    })
                  }
                  />
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%", sm: "400px", md: "500px",
                    borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#CBD5E1",
                      },
                    },
                  },
                  height: "82px", marginBottom: "16px"
                }}
              >
                <InputLabel sx={styles.inputLabelText}>Email Address<span style={{ color: "red" }}>*</span></InputLabel>
                <TextField
                  value={this.state.contactusFormData.emailAddress}
                  data-testid="emailField"
                  error={Boolean(this.state.emailError)}
                  helperText={this.state.emailError}
                  type="email" placeholder="email@example.com" fullWidth
                  sx={styles.inputField} 
                  onChange={(e) =>
                    this.setState({
                      contactusFormData: {
                        ...this.state.contactusFormData,
                        emailAddress: e.target.value,
                      },
                      emailError:''
                    })
                  }
                  />
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%", sm: "400px", md: "500px",
                    borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#CBD5E1",
                      },
                    },
                  },
                  height: "82px", marginBottom: "16px"
                }}
              >
                <InputLabel sx={styles.inputLabelText}>Contact Number<span style={{ color: "red" }}>*</span></InputLabel>
                <StyledPhoneInput
                  country={'us'}
                  inputClass={Boolean(this.state.contactNumberError)?"contactNumebrError":''}
                  value={this.state.contactusFormData.contactnumber}
                  data-test-id="PhoneInput"
                  onChange={(phone: string) =>
                    this.setState({
                      contactusFormData: {
                        ...this.state.contactusFormData,
                        contactnumber: phone,
                      },
                      contactNumberError:''
                    })
                  }
                />
                {Boolean(this.state.contactNumberError)&&<span style={{fontSize: "14px",fontFamily:"Poppins", color: "rgb(235, 22, 7)",}}>{this.state.contactNumberError}</span>}
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%", sm: "400px", md: "500px",
                    borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#CBD5E1",
                      },
                    },
                  },
                  height: "82px", marginBottom: "16px"
                }}
              >
                <InputLabel sx={styles.inputLabelText}>Subject<span style={{ color: "red" }}>*</span></InputLabel>
                <TextField
                  data-testid="subjectField" type="Text" placeholder=" What do you want to talk about?" fullWidth
                  sx={styles.inputField}
                  value={this.state.contactusFormData.subject}
                  error={Boolean(this.state.subjectError)}
                  helperText={this.state.subjectError}
                  onChange={(e) =>
                    this.setState({
                      contactusFormData: {
                        ...this.state.contactusFormData,
                        subject: e.target.value,
                      },
                      subjectError:''
                    })
                  }
                  />
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%", sm: "400px", md: "500px",
                    borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#CBD5E1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#CBD5E1",
                      },
                    },
                  },
                  marginBottom: "16px"
                }}
              >
                <InputLabel sx={styles.inputLabelText}>Details<span style={{ color: "red" }}>*</span></InputLabel>
                <TextField
                  fullWidth
                  placeholder="Any details you would like to share?"
                  multiline
                  error={Boolean(this.state.dettailsError)}
                  helperText={this.state.dettailsError}
                  minRows={2}
                  sx={styles.inputField}
                  value={this.state.contactusFormData.details}
                  data-test-id="detailsError"
                  onChange={(e) =>
                    this.setState({
                      contactusFormData: {
                        ...this.state.contactusFormData,
                        details: e.target.value,
                      
                      },
                      dettailsError:''
                    })
                  }
                />
              </Box>
              <Button
                variant="contained"
                data-testid="loginBtn"
                fullWidth
                sx={styles.loginButton}
                onClick={this.handlesubmitFormData}
              >
                Send message
              </Button>
            </Box>
          </Box>
        </Box>
          {/* Right Section */}
         <Box
            style={styles.cooksyImgBox}
            sx={{
              width: { xs: "100%", md: "auto" },
            }}
          >
            <img
              src={rightImage}
              alt="images"
              style={styles.cooksyImg}
            />
        </Box>
        <Dialog
         open={this.state.acknolodgeDialog}
         sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px",
            padding: "20px",
            textAlign: "center",
            width:"623px"
          },
        }}
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <CheckCircleOutlineIcon sx={{ fontSize: 130, color: "#059669" }} />
        </Box>
        <Box sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h5" style={{color:"#0F172A", fontSize:"24px", fontFamily:"Poppins", fontWeight:700}}> Message sent successfully!</Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 1, mb: 3 }} style={{color:"#0F172A", fontSize:"18px", fontFamily:"Poppins", fontWeight:300}}>
              We will get back to you soon!
            </Typography>
        </Box>
          </DialogContentText>
          <Button
          fullWidth
          variant="contained"
          sx={{
            fontFamily:"Poppins",
            backgroundColor: "#470D21",
            color: "white",
            fontWeight: "bold",
            borderRadius: "8px",
            padding: "12px",
            "&:hover": { backgroundColor: "#320A16" },
          }}
          data-test-id="closeBtn"
          onClick={this.handleClose}
        >
          Back to home
        </Button>
        </DialogContent>
         
        </Dialog>
          
       </Box>


      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start

const StyledPhoneInput = styled(PhoneInput)({
  "& input::placeholder":{
      fontFamily: "Poppins !important"
  },
  "& input":{
      fontFamily: " !important",
      height: '56px !important',
      width:"100% !important",
      borderRadius:"8px !important",
      borderColor:"#CBD5E1 !important",
      paddingTop: "16.5px !important",
      paddingBottom: "16.5px"
  },
  "& .flag-dropdown":{
    border: "0 !important",
    backgroundColor: "transparent !important",
    left:"8px"
  },
"& .contactNumebrError":{
    border: "1px solid #d32f2f !important"
},
  "&:hover .selected-flag, ":{
    backgroundColor: "transparent !important",
  },

  "&:focus .selected-flag, ":{
    backgroundColor: "transparent !important",
  }
})

const styles = {
  customeClass:{
    "& .MuiBox-root":{
      minHeight: "82px !important",
      height: "auto !important",
      marginBottom: "24px",

      "& .MuiFormControl-root":{
          marginBottom:"0",
        },
    }
  },

  cooksyImgBox:{
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cooksyImg : {
    width: "100%",
    maxWidth: "698px",
    borderRadius: 24,
    minHeight: "868px",
    height: "100%"
  },
  contactusText:{
    fontFamily: 'Poppins !important' ,
    fontWeight:"700",
    fontSize:30,
    color:"#000000",
  },

  captionText:{
    fontFamily:"Poppins !important",
    fontWeight:"400",
    fontSize:16,
    color:"#475569",
    marginBottom:'32px'
  },
  inputLabelText:{
    fontFamily:"Poppins !important",
    fontSize:14,
    color:"#334155",
    marginBottom:"4px",
    fontWeight:"700",
  },

  inputField:{
    borderRadius: "8px",
    "& .MuiInputBase-input":{
          fontFamily: "Poppins",
    },
   
    "& .MuiOutlinedInput-root":{
            borderRadius: "8px",
            border:"1px solid #CBD5E1",
        },
        "& .MuiInputBase-input::placeholder": {
            fontFamily: "Poppins",
            color: "#94A3B8", 
            fontWeight: 400, 
            fontSize: "16px",
        },
        "& .MuiOutlinedInput-root fieldset": {
            border: "none", 
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "& .Mui-error.MuiOutlinedInput-root":{
          border:"1px solid rgb(235, 22, 7)"
        },

        "& .MuiFormHelperText-root":{
          fontSize: "14px",
          color: "rgb(235, 22, 7)",
          marginLeft:"0",
        },
    mb: 2, 
},

loginButton:{
  mb: 2,
  width: { xs: "100%", sm: "400px", md: "500px" },
  borderRadius:"8px",
  fontFamily:"Poppins !important",
  fontWeight:"700",
  fontSize:16,
  color:"#FFFFFF",
  backgroundColor:"#44041C",
  height: "56px",
  textTransform:"none" as "none",
  marginBottom:"24px",
  marginTop:"32px",
  '&:hover': {
          backgroundColor: "#44041C",
  },
},

alert:{
  marginTop: "32px",
  marginBottom:"32px", 
  borderRadius: "4px", 
  padding: "8px 16px", 
  borderLeft: "4px solid #DC2626", 
  backgroundColor: "#FEE2E2", 
  color: "#DC2626",
  fontFamily:"Poppins !important",fontWeight:400,fontSize:"12px"
},
}

// const modalStyle = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   minWidth: 600,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// Customizable Area End
