import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import {getStorageData} from '../../../framework/src/Utilities'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountName:string;
  ibanValue:any;
  userProfileWholeData:any;
  loginUserToken:string;
  activeStep:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableSellerPaymentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  sellerProfileApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      accountName:"",
      ibanValue:null,
      userProfileWholeData:null,
      loginUserToken:"",
      activeStep:this.getActiveStep(),
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const loginApiToken = await getStorageData("loginToken")
    this.setState({loginUserToken:loginApiToken})
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.sellerProfileApiCallId) {
        if(responseJson.data){
          toast.success("Now User Become a seller");
        }
        else if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
          toast.error(responseJson.errors[0]);
        }
        else{
          toast.error(responseJson.error);
        }
      }
      }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      if (data) {
        this.setState({userProfileWholeData : data})
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getActiveStep = () => { 
    const path = window.location.pathname;
    if (path.includes("CustomisableSellerPayment")) return 2;
    if (path.includes("CustomisableSellerExperience")) return 1;
    return 0;
  };
  urlToFile = async (url:any, filename:any, mimeType:any) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };
 
  sellerProfileApi = async  () => {
    const {userProfileWholeData,loginUserToken,accountName,ibanValue} = this.state
    const header = {
     token : loginUserToken
    };
    const formData = new FormData();
  
    formData.append("become_seller[first_name]",userProfileWholeData?.userProfileData?.firstName);
    formData.append("become_seller[last_name]", userProfileWholeData?.userProfileData?.last_name);
    formData.append("become_seller[title]", userProfileWholeData?.userProfileData?.title);
    formData.append("become_seller[display_name]", "John");
    formData.append("become_seller[account_name]", accountName);
    formData.append("become_seller[description]", userProfileWholeData?.userProfileData?.description);
    formData.append("become_seller[timezone]", userProfileWholeData?.userProfileData?.timeZone);
    formData.append("become_seller[work_history]", userProfileWholeData?.workHistory);
    formData.append("become_seller[iban]",ibanValue);
    formData.append("become_seller[experience_levels][cooking_classes]", userProfileWholeData?.selectedCookingExperience);
    formData.append("become_seller[experience_levels][private_chef]", userProfileWholeData?.selectedPrivateChef);
    formData.append("become_seller[experience_levels][sous_chef]", userProfileWholeData?.selectedSousChef);
    formData.append("become_seller[experience_levels][restaurant_consulting]", userProfileWholeData?.selectedRestaurantConsulting);
    formData.append("become_seller[experience_levels][franchise_consulting]", userProfileWholeData?.selectedFranchiseConsulting);
    formData.append("become_seller[experience_levels][catering_services]", userProfileWholeData?.selectedCateringServices);
    formData.append("become_seller[experience_levels][executive_chef]", userProfileWholeData?.selectedExecutiveChef);
    formData.append("become_seller[experience_levels][culinary_content_creator]", userProfileWholeData?.selectedCulinaryContent);
    formData.append("become_seller[resume_pdf]", userProfileWholeData?.resumeUploaded);
   
    if (userProfileWholeData?.userProfileData?.avatarImage) {
      const file = await this.urlToFile(userProfileWholeData.userProfileData.avatarImage, "profile_picture.jpg", "image/jpeg");
      formData.append("become_seller[profile_picture]", file);
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sellerProfileApiCallId = requestMessage.messageId;
  
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_profile/become_sellers");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
