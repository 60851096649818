import React from 'react';
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid, styled,
  TextField, IconButton, InputAdornment,
  Divider, Dialog, DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DatePicker from "react-datepicker";
import { ToastContainer } from 'react-toastify';
import moment from "moment";
import Rating from '@mui/material/Rating';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import StarIcon from '@mui/icons-material/Star';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Slider from "react-slick";
// Customizable Area End
// Customizable Area Start
import AppointmentsController, {
  Props,
} from './AppointmentsController';
import Header from '../../landingpage/src/Header.web'

const ThumbNailNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "45%",
        right: "0%",
        transform: "translateY(-50%)",
        zIndex: 1,
        width: "24px",
        height: "24px",
        color: "#000000"
      }}
    >
      <ArrowForwardIosIcon sx={{
        width: "12px",
        height: "12px", color: "#000000"
      }} />
    </IconButton>
  );
};
const breadcrumbs = [
  <Link sx={{
    width: { xs: "218px", sm: "218px", md: "100%", lg: "100%" },
    underline: "none", key: "1", color: "#446EE7", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px"
  }} href="/" >
    Franchise Development
  </Link>,
  <Link
    sx={{ underline: "none", key: "2", color: "#446EE7", fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}
  >
    Franchise Strategy Planning
  </Link>
];
const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        right: "-2.5%",
        top: "52%",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#fff",
        boxShadow: "0px 1px 2px 0px",
        "&:hover": { backgroundColor: "gray" },
      }}
    >
      <ArrowForwardIosIcon sx={{
        width: "16px",
        height: "16px", color: "#000000"
      }} />
    </IconButton>
  );
}
const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "52%",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#ffffff",
        boxShadow: "0px 1px 2px 0px",
        left: "-2.5%",
        "&:hover": { backgroundColor: "gray" },
      }}
    >
      <ArrowBackIosIcon sx={{
        width: "16px",
        height: "16px", color: "#000000"
      }} />
    </IconButton>
  );
};
const timeSlots = ["5:30 PM", "6:30 PM", "7:30 PM", "8:30 PM", "9:30 PM"];
// Customizable Area End
export default class Appointments extends AppointmentsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { appointmentData, selectedDate } = this.state;
    const momentDate = moment(selectedDate);
    const formattedDate1 = momentDate.format('ddd Do. MMM YYYY');
    const isSameMonth = (date:any) =>
      date.getMonth() === this.state.currentMonth && date.getFullYear() === this.state.currentYear;
    const formattedDate = moment(selectedDate)
    .utcOffset(60)
    .format('dddd Do. MMMM');
    let formattedSession = "";

      if (this.state.selectedDate) {
        formattedSession = formattedDate1;
        if (this.state.selectedTime) {
          formattedSession += `, ${this.state.selectedTime.from}`;
        }
      }
    const highlightedDates: Date[] = this.getHighlightedDates();
    
    
    const value = 4.9;
    const sliderSettings = {
      beforeChange: this.handleBeforeChange,
      dots: false,
      infinite: appointmentData?.attributes?.listing_images?.length > 1,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    const thumbnailSettings = {
      slidesToScroll: 1,
      width: "100%",
      centerMode: true,
      color: "#000000",
      nextArrow: <ThumbNailNextArrow />,
      slidesToShow: appointmentData?.attributes?.listing_images?.length || 1,
      focusOnSelect: true,
      infinite:false,
    };

    return (
      <Box sx={{ overflowX: "hidden" }}>
        <Header navigation={undefined} id={''} />
        <Box sx={{ padding: "0px 0px 0px 0px", margin: { xs: "43px 15px 0px 16px", sm: "43px 15px 0px 16px", md: "43px 64px 0px 72px", lg: "43px 64px 30px 72px" }, boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)", borderRadius: "16px" }}>
          <Grid container columnSpacing={10} sx={{ padding: "24px" }}>
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
              <Box marginTop="14px">
                <Typography sx={{
                  width: { xs: "225px", sm: "225px", md: "250px", lg: "100%" },
                  fontFamily: "Poppins", fontWeight: 700, fontSize: "28px", color: "#404145"
                }}>
                  {appointmentData?.attributes?.title}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" marginTop="20.39px">
                <Box>
                  <Stack direction="row" spacing={2}>
                    <Avatar alt="Remy Sharp" src="https://picsum.photos/200" />
                  </Stack>
                </Box>

                <Box display="flex" flexDirection="row"
                  alignItems="center"
                  marginLeft="10px"
                  gap="10px"
                >
                  <Box>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "13px", color: "#404145" }}>{appointmentData?.attributes?.created_by}</Typography>
                  </Box>
                  <Box>
                    |
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box>
                      <Rating
                        name="text-feedback"
                        value={value}
                        readOnly
                        precision={0.5}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                    </Box>
                    <Box marginLeft="5px" alignItems="center">
                      <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "13px", color: "#FFB33E" }}>{value}</Typography>
                    </Box>
                    <Box marginLeft="5px">
                      <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "13px", color: "#62646A" }}>(902)</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{
                position: "relative",
                ".slick-list": {
                  padding: "0px",
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" }
                },
                ".slick-slide": {
                  height: "auto !important",
                }, marginTop: "24px"
              }} >
                <Slider {...sliderSettings}
                  ref={this.mainSliderRef}>
                  {appointmentData?.attributes?.listing_images?.map((item: any, index: number) => (

                    <Box key={index} sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" }, height: { xs: "260px", sm: "260px", md: "340px", lg: "427px" } }}>
                      <img
                        src={item.url}
                        alt={`Slide ${index}`}
                        style={{
                          width: "100%",
                          height: "427px"
                        }}
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
              <Box sx={{
                width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                marginTop: "5px",
                ".slick-list": {
                  padding: "0px !important", 
                  width: "auto",
                },
                ".slick-slide": {
                  width: "100px !important",
                  height: "auto"
                },
              }}>
                {/* Thumbnail Slider */}
                <Slider {...thumbnailSettings} key={this.state.activeIndex}  ref={this.thumbnailSliderRef}>
                  {appointmentData?.attributes?.listing_images?.map((item: any, index: number) => (
                    <Box key={index} onClick={() => this.handleThumbnailClick(index)}>
                      <img
                        src={item.url}
                        alt={`Thumbnail ${index}`}
                        style={{
                          width: "90px",
                          height: "60px",
                          borderRadius: "4px",
                          filter: this.state.activeIndex === index ? "none" : "blur(2px)",
                          opacity: this.state.activeIndex === index ? 1 : 0.5,
                        }}
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
              <Box maxWidth="662px" width="100%" marginTop="64px">
                <Typography sx={{ color: "#404145", fontFamily: "Poppins", fontWeight: 700, fontSize: "19px" }}>
                  About This Service
                </Typography>
                <Typography sx={{ color: "#62646A", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px" }}>
                  {appointmentData?.attributes?.description}
                </Typography>
              </Box>
              <Box marginTop="24.61px">
                <Divider color="#DADBDD" />
              </Box>
              <Typography sx={{ marginTop: "35px", color: "#404145", fontFamily: "Poppins", fontWeight: 700, fontSize: "20px" }}>About The Seller</Typography>
              <Box display="flex" flexDirection="row" marginTop="25px">
                <Box>
                  <Avatar alt="Remy Sharp" src="https://picsum.photos/200" sx={{ width: "132px", height: "132px" }} />
                </Box>
                <Box sx={{ marginLeft: "15px", width: { xs: "150px", sm: "150px", md: "100%", lg: "100%" } }}>
                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "15px", color: "#62646A" }}>{appointmentData?.attributes?.created_by}</Typography>
                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "18px", color: "#62646A", marginTop: "4px" }}>Chef Trainer</Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box>
                      <Rating
                        style={{ marginTop: "6px" }}
                        name="text-feedback"
                        value={value}
                        readOnly
                        precision={0.5}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                      />
                    </Box>
                    <Box display="flex" flexDirection="row" marginTop="7px">
                      <Box marginLeft="5px" alignItems="center">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "13px", color: "#FFB33E" }}>{value}</Typography>
                      </Box>
                      <Box marginLeft="5px">
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "13px", color: "#62646A" }}>(974)</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "20px", width: "100%", display: "flex", flexDirection: "row" }}>
                    <Box sx={{ marginRight: { xs: "5px", sm: "5px", md: "9.34px", lg: "9.34px" }, borderRadius: "4px", height: { xs: "50px", sm: "50%", md: "40px", lg: "40px" }, width: "134px", textAlign: "center", border: "1px solid #62646A" }}>
                      <Button sx={{ color: "#62646A", fontFamily: "Poppins", fontWeight: 600, fontSize: { xs: "11px", sm: "11px", md: "15px", lg: "14px" }, alignItems: "center" }}>View Profile</Button>
                    </Box>
                    <Box sx={{ borderRadius: "4px", height: { xs: "50px", sm: "50%", md: "40px", lg: "40px" }, width: "134px", textAlign: "center", border: "1px solid #62646A" }}>
                      <Button sx={{ color: "#62646A", fontFamily: "Poppins", fontWeight: 600, fontSize: { xs: "11px", sm: "11px", md: "15px", lg: "14px" }, alignItems: "center" }}>Contact Me</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box border="1px solid #E7E5E4" borderRadius="12px" padding="24px"
                marginTop="24px"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#74767E" }}>Location</Typography>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "15px", color: "#62646A" }}>New York CIty, NY, USA</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#74767E" }}>Member  <br /> since</Typography>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "15px", color: "#62646A" }}>Aug 2019</Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "25.61px" }} color="#DADBDD" />
                <Box marginTop="16px">
                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#62646A" }}>
                    At Airbluesoft Premium Digital Studio we create all kinds of creative videos, specializing in Creating Promos( Website, Apps, Fashion, Real Estate, Youtube, NFT) and all other promos and all instructional videos.<br />
                    <br />
                    We Create Basic To High-End Videos.
                    <br />
                    <br />
                    Creativity Beyond the Limits.
                    <br />
                    -Airbluesoft Premium Digital Studio-
                  </Typography>
                </Box>

              </Box>
            </Grid>
            {this.state.showCalendar && (
              <Dialog
              sx={{
                  padding: "32px",
                  "& .MuiPaper-root": {
                    borderRadius: "16px",
                    maxWidth: "739px",
                    width: "739px",
                    height: "640px",
                    overflow: "hidden",
                    "@media (max-width: 768px)": {
                      width: "90%", 
                      height: "auto",
                    },
                  },
                }}
                open={this.state.showCalendar}
              >
                <DialogContent>
                <ToastContainer />
                  <Box sx={{ borderRadius: "16px", overflow: "hidden", display: "flex", justifyContent: "center",flexWrap: "wrap" }}>
                    <Box sx={{
                      width: "100%", justifyItems: "start", alignItems: "center",
                      "@media (max-width: 768px)": {
                        padding: "16px",
                      },
                    }}
                       >
                      <Box sx={{
                        display: "flex", flexDirection: "row", gap: "330px", "@media (max-width: 768px)": {
                          gap: "20px", 
                          flexDirection: "column",
                          alignItems: "center",
                        },
                      }}>
                        <Box>
                          <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px" }}>Choose your date & time</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <CloseIcon
                            onClick={this.handleClose}
                            style={{ cursor: "pointer" }}
                          />
                        </Box>
                      </Box>
                      <Box width="100%">
                        <Divider sx={{ width: "100%", marginTop: "32px", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
                      </Box>

                      <Box 
                      sx={{marginTop:"32px", 
                      width:"100%" ,display:"flex" ,
                      justifyContent:"space-between" ,
                      flexDirection:{xs:"column",sm:"row",md:"row",lg:"row"}}}>
                        <Box>
                          <StyledDatePickerWrapper>
                            <DatePicker
                              selected={this.state.selectedDate} 
                              onChange={this.handleDateChange}
                              inline
                              calendarStartDay={1} 
                              highlightDates={highlightedDates}
                              formatWeekDay={(day) => {
                                const dayIndex = new Date(day).getDay();
                                const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                                return weekDays[dayIndex]; 
                              }}
                              onMonthChange={(date) => {
                                this.setState({currentMonth: date.getMonth()})
                                this.setState({currentYear:date.getFullYear()})
                              }}
                              dayClassName={(date) => (isSameMonth(date) ? "" : "hidden-date")}
                            />
                          </StyledDatePickerWrapper>
                        </Box>
                        <Box>
                        <Box sx={{ borderLeft: "1px dashed #D5DFDF", height: "100%", }} />
                        </Box>
                        <Box>
                          {this.state.selectedDate ? (
                            <Box  width="100%" 
                            display="flex" 
                            flexDirection="row" 
                           >
                              <Box display="flex" flexDirection="column">
                                
                                <Box>
                                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "16px", color: "#192020" }}>
                                    {formattedDate}
                                  </Typography>
                                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 300, fontSize: "16px", color: "#192020" }}>
                                    (Timezone is GMT+1:00)
                                  </Typography>
                                
                                </Box>
                               
                                <Box
                                  style={{
                                   
                                    flexDirection: "column",
                                    marginTop: "55px",
                                    borderRadius: "10px",
                                    width: "190px",

                                    display: "flex",
                                    
                                  }}
                                >
                                  {this.getFilteredTimeSlots()?.map((time: any) => {
                                      const isPastSlot =
                                      this.state.selectedDate &&
                                      moment(this.state.selectedDate).isSame(moment(), "day") && // Check if selected date is today
                                      moment(time.from, "HH:mm").isBefore(moment(), "minute")
                                    return (
                                    <Button
                                      key={time}
                                      onClick={() => !isPastSlot && this.handleTimeChange(time)} 
                                      disabled={isPastSlot} 
                                      style={{
                                        margin: "5px 0",
                                        padding: "10px",
                                        backgroundColor: this.state.selectedTime === time ? "#44041C" : "#fff",
                                        color: this.state.selectedTime === time ? "#fff" : "#000",
                                        border: "1px solid #ccc",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        fontFamily:"Poppins",fontWeight:400,fontSize:"18px"
                                      }}
                                    >
                                      {time?.from}
                                    </Button>
                                  )})}
                                </Box>
                              </Box>
                            </Box>
                          ) :
                            <Box display="flex" flexDirection="row" width="211px">
                              <Box display="flex" flexDirection="column">
                                <Box
                                  style={{
                                    display: "flex",
                                    width: "190px",
                                    flexDirection: "column",
                                    marginTop: "55px",
                                    borderRadius: "10px"
                                  }}
                                >
                                  {timeSlots?.map((time: any) => (
                                    <Button
                                      key={time}
                                      data-test-id="timeSlot"
                                      onClick={() => this.handleTimeChange(time)}
                                      style={{
                                        margin: "5px 0",
                                        padding: "10px",
                                        backgroundColor: this.state.selectedTime === time ? "#44041C" : "#fff",
                                        color: this.state.selectedTime === time ? "#fff" : "#04434352",
                                        border: "1px solid #ccc",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        fontFamily:"Poppins",fontWeight:400,fontSize:"18px"
                                      }}
                                    >
                                      {time}
                                    </Button>
                                  ))}
                                </Box>
                              </Box>
                            </Box>}
                        </Box>

                      </Box>

                      <Box width="100%">
                        <Divider sx={{ marginTop:"32px",width: "100%", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
                      </Box>
                      <Box sx={{padding:{xs:"0px",sm:"15px",md:"28px",lg:"28px"}, display:"flex" ,flexDirection:"row", justifyContent:"space-between", gap:"20px", justifySelf:"end"}}>
                        <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <Button sx={{ textTransform:"none",fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                            Cancel
                          </Button>
                        </Box>
                        <Box data-test-id="continue" onClick={this.appointmentContinueBtnBooking} sx={{ width: "91px", height: "44px", borderRadius: "8px", backgroundColor: "#44041C", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <Button sx={{textTransform:"none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                            Continue
                          </Button>
                        </Box>
                      </Box>

                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            )}


            <Grid item xs={0} md={4}>
              <Box sx={{ marginTop: "40px", border: "1px solid #E7E5E4", padding: { xs: "0px 32px", sm: "0px 32px", md: "24px", lg: "24px" }, borderRadius: "12px", width: "auto" }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>Service - Chef Training</Typography>
                    <Typography sx={{ marginTop: "32px", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>Service Fee 5%</Typography>
                    <Typography sx={{ marginTop: "32px", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>Small order fee</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>$83</Typography>
                    <Typography sx={{ marginTop: "32px", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>$4.3</Typography>
                    <Typography sx={{ marginTop: "32px", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>$3</Typography>
                  </Box>

                </Box>
                <Divider sx={{ marginTop: "32px" }} color="#000000" />
                <Box marginTop="16px" display="flex" flexDirection="row" justifyContent="space-between">
                  <Box>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>Total:</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#000000" }}>
                      $94.3
                    </Typography>
                  </Box>
                </Box>
                <Box marginTop="42px" width="100%">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Pick a session"
                    value={formattedSession}
                    onClick={this.handleCalendarOpen}
                    InputProps={{
                      style: {
                        width: "100%",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "10px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <UnfoldMoreIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        width: "100%",
                        fontFamily: "Poppins", 
                        fontWeight:300,
                        fontSize:"16px",
                        color:"#192020"
                      },
                      "& .MuiInputBase-input::placeholder": {
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#94A3B8"
                      },

                      "& input[type='number']::-webkit-outer-spin-button, & input[type='number']::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      }
                    }}
                  />
                  {this.state.validationError && (
                    <Typography color="error" sx={{ fontFamily: "Poppins", fontSize: "14px", marginTop: "8px" }}>
                      {this.state.validationError}
                    </Typography>
                  )}
                </Box>
                <Box data-test-id="bookBtn" marginTop="26px" onClick={this.appointmentBookNow}>
                  <Button
                    sx={{
                      width: "100%",
                      height: "44px",
                      borderRadius: "8px",
                      padding: "16px", textTransform: "none", backgroundColor: "#44041C",
                      color: "#ffffff", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#44041C"
                      },

                    }}>
                    Book now
                  </Button>
                </Box>

                <Box marginTop="17px">
                  <Button sx={{
                    width: "100%",
                    height: "44px",
                    borderRadius: "8px",
                    border: "1px solid #44041C",
                    padding: "16px", textTransform: "none", color: "#44041C", fontFamily: "Poppins", fontWeight: 600, fontSize: "14px"
                  }}>
                    Contact Seller
                  </Button>
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledDatePickerWrapper = styled("div")`
  .react-datepicker {
    border: none !important;
  },
  .hidden-date {
  visibility: hidden;
}
  .react-datepicker__month-container {
    width: 384px !important;
    height: 372px !important;
  },
  .react-datepicker__day--highlighted {
    color: #263238 !important;
    background-color:#44041C17 !important;
    border-radius: 18px
  }
  .react-datepicker__day--selected {
  background-color: #44041C !important;
  color: white !important;
  border-radius: 50%; /* Optional: to make it look like a circular button */
},
.react-datepicker__week {
  margin-bottom: 10px
},
  .react-datepicker__current-month {
  font-family: 'Poppins' !important;
  font-weight: 300; 
  font-size: 20px !important; / Remove quotes */
  color: #263238;
  margin-bottom : 20px
}
 .react-datepicker__day-names {
  display: flex;
  justify-content: space-around;

}
  .react-datepicker__day {
   font-family: 'Poppins' !important; 
  font-weight: 400; 
  font-size: 17.14px !important; 
  color: #BFC9C9;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__header {
   border-bottom : none;
   background-color:#ffffff
  }
  .react-datepicker__navigation--previous {
   left:54px;
   top:9px
  },

   .react-datepicker__navigation--next {
    top : 9px;
    right : 50px
    }
   .react-datepicker__navigation svg {
  fill: grey !important; /* For SVG arrows */
}
  .react-datepicker__day-names {
   font-family: 'Poppins' !important; 
  font-weight: 400; 
  font-size: 13.71px !important; 
  color: #3C413F;
  }
  @media only screen and (max-width: 374px) {
  .react-datepicker__month-container {
    width: 180px !important; 
  }

  .react-datepicker__day {
    font-size: 12px !important; 
  }

  .react-datepicker__current-month {
    font-size: 14px !important; 
  }
}
   @media only screen and (max-width: 375px) {
  .react-datepicker__month-container {
    width: 200px !important;
  }

  .react-datepicker__day {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 576px) {
  .react-datepicker__month-container {
    width: 250px !important;
  }

  .react-datepicker__day {
    font-size: 15px !important;
  }

  .react-datepicker__current-month {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .react-datepicker__month-container {
    width: 300px !important;
  }

  .react-datepicker__day {
    font-size: 16px !important;
  }

  .react-datepicker__current-month {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .react-datepicker__month-container {
    width: 330px !important;
  }

  .react-datepicker__day {
    font-size: 17px !important;
  }

  .react-datepicker__current-month {
    font-size: 20px !important;
  }
}
  `
  
// Customizable Area End
