import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {getStorageData} from '../../../framework/src/Utilities'
import { toast } from "react-toastify";
import dayjs from "dayjs";
import React from "react";
interface ImageFile {
  id: string;
  file: File;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedButton: string;
  addData: (object | number)[];
  index: number;
  header: string;
  before_title: string;
  after_title: string;
  before_description: string;
  after_description: string;
  in_the_bottom: string;
  active: boolean;
  selected: number;
  name: string;
  color: string;
  lists: (object | number)[];
  item: string;
  showParticularAdCallId: string;
  openDialogue:boolean;
  // Customizable Area Start
  image:any;
  title:string;
  description:string;
  subCategoryId:any;
  price:any;
  duration:any;
  dragActive:any;
  uploadedImage: ImageFile[];
  categorySubData:any;
  createListingData:any;
  selectedCategory:any;
  selectedCategoryId:any;
  selectedSubCategory:any;
  subCategories: any[];
  calendarOpen:boolean;
  timeSlots:any;
  isChecked:boolean;
  selectedDate:any;
  selectedSlots:any;
  // Customizable Area End
}

interface SS {
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

export default class LocalListingAdsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // showParticularAdCallId: string;
  showParticularAdCallId: string = "";
  createListingApiCallId: string = "";
  fileInputRef: any;
  categorySubcategoryFetchApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.showParticularAdCallId = "";
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      timeSlots: [{ start: "", end: "" }],
      uploadedImage: [],
      dragActive:false,
      title:"",
      description:"",
      subCategoryId:"",
      price:null,
      duration:"",
      token: "",
      selectedButton: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      index: 0,
      addData: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }],
      header: "",
      before_title: "",
      after_title: "",
      before_description: "",
      after_description: "",
      in_the_bottom: "",
      active: false,
      selected: 0,
      name: "bob",
      color: "white",
      item: " ",
      openDialogue:false,
      showParticularAdCallId: "",
      lists: [
        { id: 1, title: "S" },
        { id: 2, title: "M" },
        { id: 3, title: "L" },
        { id: 4, title: "XL" },
        { id: 5, title: "XXL" },
      ],
      image:"",

      // Customizable Area Start
      categorySubData:[],
      createListingData:[],
      selectedCategory:null,
      selectedSubCategory:null,
      subCategories: [],
      calendarOpen:false,
      isChecked:false,
      selectedDate:null,
      selectedSlots:{},
      selectedCategoryId:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }
  async componentDidMount() {
    const loginApiToken = await getStorageData("loginToken")
    this.setState({token:loginApiToken})
    this.showParticularAdFunc();
    this.fetchAllCategoriesApi();
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({
        header: responseJson.header,
        before_title: responseJson.before_title,
        after_title: responseJson.after_title,
        before_description: responseJson.before_description,
        after_description: responseJson.after_description,
        in_the_bottom: responseJson.in_the_bottom,
      });


      if(apiRequestCallId === this.categorySubcategoryFetchApiCallId){
        this.setState({categorySubData : responseJson.data})
      }
      if(apiRequestCallId === this.createListingApiCallId){
        if(responseJson?.data){
          this.setState({createListingData : responseJson.data,
            openDialogue:false
          })
        }
        else if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
          toast.error(responseJson.errors[0]);
        }
        else {
          toast.error(responseJson.errors);
        }
      }}
  }
  // Customizable Area End
  handleCheckboxChange = () => {
    this.setState((prevState) => ({
        isChecked: !prevState.isChecked,
    }));
  };
  handleAddSlot = () => {
    const { selectedDate, selectedSlots } = this.state;
    if (!selectedDate) return;

    const dateKey = dayjs(selectedDate).format("YYYY-MM-DD");
    const updatedSlots = { ...selectedSlots };

    if (!updatedSlots[dateKey]) {
      updatedSlots[dateKey] = [];
    }

    updatedSlots[dateKey].push({ start: "", end: "" });

    this.setState({ selectedSlots: updatedSlots });
  };
  handlePress = (button: string) => {
    this.setState({ selectedButton: button });
  };

  // Customizable Area Start
  handleAddTimeSlot = () => {
    this.setState((prevState) => ({
      timeSlots: [...prevState.timeSlots, { start: "", end: "" }],
    }));
  };
  
  // Update the time slot values
 
  handleCategoryChange = (event: any) => {
    const selectedCategoryId = parseInt(event.target.value, 10);
    const selectedCategory = this.state.categorySubData.find(
      (category: any) => category.attributes.id === selectedCategoryId
    );
  
    this.setState({
      selectedCategory: selectedCategoryId,
      subCategories: selectedCategory?.attributes?.sub_categories || [],
      selectedSubCategory: "",
    });
  };
  
  handleSubCategoryChange = (event: any) => {
    const selectedSubCategoryId = parseInt(event.target.value, 10);
  
    this.setState({
      selectedSubCategory: selectedSubCategoryId,
    });
  
  };
  
  handleDragOver = (event:React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ dragActive: true });
  };

  handleDragLeave = () => {
    this.setState({ dragActive: false });
  };

  handleDrop = (event:any) => {
    event.preventDefault();
    this.setState({ dragActive: false });
    this.handleImageUpload(event);
  };
  handleBoxClick = () => {
    this.fileInputRef.current.click(); 
  };
  handleCalendarOpen = () => {
    this.setState({calendarOpen:true})
  }
  handleTimeChange = (date:any, index:any, field:any, value:any) => {
    const updatedSlots = { ...this.state.selectedSlots };
    updatedSlots[date][index][field] = value;
    this.setState({ selectedSlots: updatedSlots });
  };

  // ❌ Remove Slot
  handleRemoveSlot = (date:any, index:any) => {
    const updatedSlots = { ...this.state.selectedSlots };
    updatedSlots[date].splice(index, 1);

    if (updatedSlots[date].length === 0) {
      delete updatedSlots[date]; // Remove date if no slots left
    }

    this.setState({ selectedSlots: updatedSlots });
  };
  handleDateChange = (date:any) => {
    this.setState({
      selectedDate: date,

    });
  };
  handleDialogueOpen = () => {
    this.setState({openDialogue:true})
  }
  handleImageUpload = (event: any) => {
    event.preventDefault();

    const files = event.target.files || event.dataTransfer?.files;

    if (files && files.length > 0) {
      const uploadedImage = [...this.state.uploadedImage];

      for (let file of files) {
        if (file.type === "image/png" || file.type === "image/jpeg") {
          uploadedImage.push({
            id: URL.createObjectURL(file),
            file: file,
          });
        } else {
          alert("Please upload valid PNG or JPEG images.");
        }
      }

      this.setState({ uploadedImage });
    }
  };
  removeImage = (imageId:any) => {
    this.setState((prevState) => ({
      uploadedImage: prevState.uploadedImage.filter(
        (image:any) => image.id !== imageId
      ),
    }));
  };
  

  fetchAllCategoriesApi = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
     const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.categorySubcategoryFetchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/categories/fetch_associate_sub_categories"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createListingCards = () => {
    const { selectedSlots,token,title, description, selectedCategory, selectedSubCategory, price, duration } = this.state;
    
    if (Array.isArray(selectedSlots) && selectedSlots?.length >0 || !title || !description || !selectedCategory || !selectedSubCategory || !price || !duration ) {
      toast.error("Please fill all fields and upload at least one image.");
      return; 
    }

    const header = {
      token: token,
    };
    const formData = new FormData();
  
    formData.append("service[title]",title);
    formData.append("service[description]", description);
    formData.append("service[sub_category_id]", selectedCategory);
    formData.append("service[duration]", duration);
    formData.append("service[price]", price);
  
    if (this.state.uploadedImage && this.state.uploadedImage.length > 0) {
      this.state.uploadedImage.forEach((imageObj: any) => {
        formData.append("service[listing_images][]", imageObj.file);
      });
    }
    const availabilityTimeSlots: { start_time: any; end_time: any; availability_date: string; }[] = [];

    Object.keys(this.state.selectedSlots).forEach((date) => {
      this.state.selectedSlots[date].forEach((slot:any) => {
        availabilityTimeSlots.push({
          start_time: slot.start,
          end_time: slot.end,
          availability_date: dayjs(date).format("DD/MM/YYYY"), 
        });
      });
    });

    formData.append("availability[time_slots]", JSON.stringify(availabilityTimeSlots));  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createListingApiCallId = requestMessage.messageId;
  
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_locallistingads/local_listing_ads/create_listing");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleClose = () => {
    this.setState({openDialogue:false})
  }
  
  
  handleDurationChange = (e:any) => {
    this.setState({duration:e.target.value})
  }
  showParticularAdFunc = async () => {
    this.showParticularAdCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_locallistingads/local_listing_ads",
    });
  };
  handlePriceChange = (e:any) => {
    this.setState({price:e.target.value})
  }
  handleTitleChange = (e:any) => {
    this.setState({title:e.target.value})
  }
  handleDescriptionChange = (e:any) => {
    this.setState({description:e.target.value})
  }

  apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
  }) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };
  // Customizable Area End
}
