import React from "react";

// Customizable Area Start
import Header from '../../landingpage/src/Header.web';
import {
  InputAdornment,IconButton, Paper,
  Divider,InputBase,
  DialogContent,Dialog,
 Box,TextField, Button, Avatar,  InputLabel, Typography, Grid
} from "@mui/material";
import {saveChangesIcon,facebookIcon,linkedinIcon,instagramIcon,printresetIcon,xIcon} from './assets'
import SocialMediaAccountRegistrationController, {
  Props
} from "./SocialMediaAccountRegistrationController";
import { ToastContainer } from 'react-toastify';
import { Visibility } from "@mui/icons-material";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
 // Customizable Area End
export default class SocialMediaAccountRegistration extends SocialMediaAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {location,bio,fullName,email,accountDeletePopup,showConfirmNewPwd,confirmNewPassword,newPwd,showConfirmPwd,showPwd,password,passwordChangePopup} = this.state;    
    return (
        <>
        <Header navigation={undefined} id={""} />
      <Box
        sx={{
          padding: { xs: "30px 20px 46px 20px", sm: "30px 20px 46px 20px", md: "30px 214px 46px 214px", lg: "116px 212px 46px 212px" },
         }}
      >
         <ToastContainer />
        <Paper elevation={0} style={{borderRadius:"24px",boxShadow: "0px 0px 10px rgb(189 179 179 / 40%)",padding: "30px", marginTop: 20 }}>
        <Grid container spacing={3}>
        {/* Edit Picture */}
      <Grid item xs={12} display="flex" justifyContent="center" sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute",
        bottom:{xs:"-20px",sm:"-35px",md:"-45px",lg:"-45px"},
        right:{xs:"45%",sm:"73%",md:"73%",lg:"78%"},
        width:{xs:"100px",sm:"120px",md:"118px",lg:"156px"},
        height:{xs:"100px",sm:"120px",md:"118px",lg:"156px"}}}>
        <Avatar  src={this.state.selectedImage} 
        sx={{ width: { xs: "100px", sm: "120px", md: "118px", lg: "156px" }, height: { xs: "100px", sm: "120px", md: "118px", lg: "156px" } }}/>
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.5)"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton 
          data-testid="edit-picture-button"
          onClick={this.handleIconClick} sx={{ color: "white",display:"flex",flexDirection:"column" }}>
            <CameraAltOutlinedIcon />
            <Typography sx={{fontWeight:700, fontFamily: "Poppins", fontSize: "14px", color: "#FFFFFF" }}>Edit picture</Typography>
          </IconButton>
          <input
              type="file"
              data-testid="file-input"
              accept="image/*"
              ref={this.fileInputRef}
              style={{ display: "none" }}
              onChange={this.handleImageChange}
            />
        </Box>
      </Box>
    </Grid>

          <Grid item xs={12} display="flex" justifyContent="right" sx={{ position: "relative" }}>
            <Box sx={{position:"absolute",bottom:{xs:"-16px",sm:"-16px",md:"-16px",lg:"-16px"},display:"flex",gap:"16px"}}>
            <Button sx={{textTransform:"none",color:"#44041C",fontFamily:"Poppins",fontWeight:600,fontSize:"16px"}}>Cancel</Button>
            <Button  data-testid="saveChanges"
            onClick={this.profileUpdateApi}
            startIcon={<img style={{width:"24px",height:"24px"}} src={saveChangesIcon} alt="saveChangesIcon"/>}  
            sx={{textTransform:"none",backgroundColor:"#44041C",color:"#FFFFFF",fontFamily:"Poppins",fontWeight:600,fontSize:"16px",
              '&:hover': {
            backgroundColor: "#44041C",  
            color: "#FFFFFF"      
        }
            }}>Save Changes</Button>
            </Box>
          </Grid>
      {/* Left Label */}
      <Grid container item marginTop="40px">
      <Grid item xs={12} sm={3}>
        <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>
          Personal Information
        </InputLabel>
      </Grid>
      
      {/* Right Form Fields */}
      <Grid item xs={12} sm={9} container spacing={2}>
        {/** Full Name */}
        <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",marginLeft:"35px",fontWeight:700, fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Full name</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          </Box>
          <TextField fullWidth  name="FullName"
            value={fullName}
            data-testid="fullName"
            onChange={(e)=>this.setState({fullName : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: "48px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>
        
        {/** Email */}
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom:"4px",fontWeight:700,marginLeft:"35px",fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Email</InputLabel>
          
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          </Box>
          <TextField fullWidth  name="Email"
           value={email}
           data-testid="email"
           onChange={(e)=>this.setState({email : e.target.value})}
           sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: "48px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>
        
        {/** Location */}
        <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",fontWeight:700,marginLeft:"35px", fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Location</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          </Box>
          <TextField fullWidth name="Location"
            value={location || ""}
            data-testid="location"
            onChange={(e)=>this.setState({location : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: "48px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
        </Box>
        </Grid>
        
        {/** Bio */}
        <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",fontWeight:700,marginLeft:"35px", fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Bio</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          </Box>
          <TextField fullWidth  name="Bio"
            data-testid="bio"
            value={bio}
            onChange={(e)=>this.setState({bio : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>

        <Grid item xs={12}>
        <InputLabel
          sx={{
            marginLeft: "35px",
            fontFamily: "Poppins",
            fontSize: "14px",
            color: "#64748B",fontWeight:700,
          }}
        >
          Password
        </InputLabel>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{ width: "35px" }} />
          <InputBase
            type="password"
            value="**********"
            disabled
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              letterSpacing: "2px",
              width: "180px",
              background: "transparent",
              border: "none",
            }}
          />
          <Box flexGrow={1} />
          <Button
            sx={{
              color: "#6200EA",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 700,fontFamily:"Poppins"
            }}
            data-testid="change-pwd--popup-button"
            onClick={this.handlePwdPopupOpen}
          >
            Change password
          </Button>
        </Box>
      </Grid>
      </Grid>
      <Divider sx={{color:"#E2E8F0",width:"100%",marginTop:"40px",marginBottom:"40px"}}/>
      {/* Social Media */}
     {/* Left Label */}
      <Grid item xs={12} sm={3}>
        <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>
          Social links
        </InputLabel>
      </Grid>
      
      {/* Right Form Fields */}
      <Grid item xs={12} sm={9} container spacing={2}>
        {/** Facebook */}
        <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",fontWeight:700,marginLeft:"35px",fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Facebook</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          <img style={{width:"14.58px",height:"26.93px"}} src={facebookIcon} alt="saveChangesIcon"/>
          </Box>
          <TextField 
             data-testid="facebook"
            fullWidth  name="Facebook"
            onChange={(e)=>this.setState({facebookUrl : e.target.value})}
            sx={{ 
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: "48px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>
        
        {/** Linkedin */}
        <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",fontWeight:700, marginLeft:"35px",fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Linkedin</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          <img style={{width:"22.58px",height:"22.58px"}} src={linkedinIcon} alt="linkedinIcon"/>
          </Box>
          <TextField
            data-testid="linkedin"
            fullWidth  name="Linkedin"
            onChange={(e)=>this.setState({linkedinUrl : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: "48px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>
        
        {/** Instagram */}
        <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",fontWeight:700, marginLeft:"35px",fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Instagram</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          <img style={{width:"24.42px",height:"24.42px"}} src={instagramIcon} alt="instagramIcon"/>
          </Box>
          <TextField fullWidth  name="Instagram"  
            data-testid="instagram"
            onChange={(e)=>this.setState({instagramUrl : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: "48px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>
        
        {/** Pinterest */}
        <Grid item xs={12}>
          <InputLabel sx={{fontWeight:700,marginBottom:"4px",marginLeft:"35px", fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>Pinterest</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          <img style={{width:"20.46px",height:"26.92px"}} src={printresetIcon} alt="instagramIcon"/>
          </Box>
          <TextField fullWidth  name="Pinterest"
            data-testid="pinterest"
            onChange={(e)=>this.setState({pinterestUrl : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>

          {/** X */}
          <Grid item xs={12}>
          <InputLabel sx={{marginBottom:"4px",fontWeight:700, marginLeft:"35px",fontFamily: "Poppins", fontSize: "14px", color: "#64748B" }}>X</InputLabel>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{width:"35px"}}>
          <img style={{width:"32px",height:"32px"}} src={xIcon} alt="xIcon"/>
          </Box>
          <TextField fullWidth  name="X"
            data-testid="xid"
            onChange={(e)=>this.setState({xurl : e.target.value})}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "& fieldset": { border: "1px solid #CBD5E1" },
                "&:hover fieldset": { border: "1px solid #CBD5E1" },
                "&.Mui-focused fieldset": { border: "1px solid #CBD5E1" },
              },
              "& .MuiInputBase-input": {
                fontFamily: "Poppins", 
              }
            }}
          />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{color:"#E2E8F0",width:"100%",marginTop:"40px",marginBottom:"40px"}}/>

      {/* Account Action */}
       {/* Left Label */}
       <Grid item xs={12} sm={3}>
        <InputLabel sx={{ fontFamily: "Poppins", fontSize: "16px", color: "#64748B", fontWeight: 700 }}>
         Account actions
        </InputLabel>
      </Grid>
      
      {/* Right Form Fields */}
      <Grid item xs={12} sm={9} container spacing={2}>
        {/** Log out */}
        <Grid onClick={this.handleLogout} item xs={12} display="flex" flexDirection="row" marginLeft="35px" gap="8px">
          <ExitToAppOutlinedIcon sx={{color:"#0F172A",width:"20px",height:"20px"}}/>
          <Typography sx={{color:"#0F172A",fontFamily:"Poppins",fontWeight:700,fontSize:"16px"}}>Log out</Typography>
        </Grid>
        
        {/** Delete */}
        <Grid item xs={12} display="flex" flexDirection="row" marginLeft="35px" gap="8px">
          <DeleteOutlineOutlinedIcon sx={{color:"#DC2626",width:"20px",height:"20px"}} />
          <Typography data-testid="open-delete-popup-button" onClick={this.handleDeletePopupOpen} sx={{textTransform:"none",color:"#DC2626",fontFamily:"Poppins",fontWeight:700,fontSize:"16px"}}>Delete account</Typography>
        </Grid>
      </Grid>
      {passwordChangePopup && 
      <Dialog
      sx={{
          padding: "32px",
          "& .MuiPaper-root": {
            borderRadius: "16px",
            maxWidth: "633px",
            width: "633px",
            height: "606px",
            overflow: "hidden",
            "@media (max-width: 768px)": {
              width: "90%", 
              height: "auto",
            },
          },
        }}
        open={passwordChangePopup}
      >
        <DialogContent>
        <ToastContainer />
          <Box sx={{ borderRadius: "16px", overflow: "hidden", display: "flex", justifyContent: "center",flexWrap: "wrap" }}>
            <Box sx={{
              width: "100%", alignItems: "center",
              "@media (max-width: 768px)": {
                padding: "16px",
              },
            }}
               >
                <Box>
                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px" }}>Change password</Typography>
                </Box>
                <Box>
                <Box
                  sx={{marginTop:"24px",
                    width: { xs: "100%", sm: "400px", md: "500px",lg:"100%" },
                    height: "82px", borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      border: "1px solid #CBD5E1"
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },

                  }}
                >
                  <InputLabel sx={webStyle.resetLabelText}>Current Password</InputLabel>
                  <TextField
                    value={password}
                    data-testid="current-pwd-field"
                    onChange={this.handlePwdChange}
                    type={showPwd ? "text" : "password"}
                    fullWidth 
                    sx={webStyle.pwdText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton  data-testid="current-pwd-icon" onClick={this.toggleShowPwd}>
                            {showPwd ? <Visibility style={{ color: "#94A3B8" }} /> :
                              <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }}
                              />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box marginBottom="16px">
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasUppercase() && (
                      <Box sx={webStyle.passwordValidBoxTop}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                      <Typography sx={webStyle.pwdConditions}>At least one capital letter</Typography>
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasLowercase() && (
                      <Box sx={webStyle.passwordValidBox}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                        <Typography sx={webStyle.pwdConditions}>At least one lowercase letter</Typography>
                      </Box>
                    )}
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasNumber() && (
                      <Box sx={webStyle.passwordValidBox}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                        <Typography sx={webStyle.pwdConditions}>At least one number</Typography>
                      </Box>
                    )}
                  
                  </Typography>
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      fontWeight: 400,
                      fontFamily:"Poppins"
                    }}
                  >
                    {this.hasSpecialCharacter() && (
                      <Box sx={webStyle.passwordValidBox}>
                        <CheckCircleIcon style={webStyle.greenCheck} />
                        <Typography sx={webStyle.pwdConditions}>At least one special character</Typography>
                      </Box>
                    )}
                    
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "400px", md: "500px",lg:"100%" },
                    height: "82px", marginBottom: "16px",borderColor: "#CBD5E1",
                   
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",

                      border: "1px solid #CBD5E1"
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "none",
                    },
            
                  }}
                >
                  <InputLabel sx={webStyle.resetLabelText}>New Password</InputLabel>
                  <TextField 
                    value={newPwd}
                    onChange={this.handleConfirmPwdChange}
                    data-testid="confirm-pwd-field"
                    type={showConfirmPwd ? "text" : "password"}
                    fullWidth sx={webStyle.pwdText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton data-testid="confirm-pwd-icon" onClick={this.toggleShowConfirmPwd}>
                            {showConfirmPwd ? <Visibility style={{ color: "#94A3B8" }} /> :

                              <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }}
                              />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "400px", md: "500px" ,lg:"100%"},
                    height: "82px", borderColor: "#CBD5E1",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      border: "1px solid #CBD5E1"
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },

                  }}
                >
                  <InputLabel sx={webStyle.resetLabelText}>Confirm New Password</InputLabel>
                  <TextField
                    value={confirmNewPassword}
                    data-testid="confirm-new-field"
                    onChange={this.handleConfirmNewPwdChange}
                    type={showConfirmNewPwd ? "text" : "password"}
                    fullWidth 
                    sx={webStyle.pwdText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton data-testid="confirm-new-icon"  onClick={this.toggleShowNewConfirmPwd}>
                            {showConfirmNewPwd ? <Visibility style={{ color: "#94A3B8" }} /> :
                              <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }}
                              />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              </Box>
              <Box width="100%">
                <Divider sx={{ marginTop:"32px",width: "100%", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
              </Box>
              <Box sx={{marginLeft:"45%",padding:{xs:"0px",sm:"15px",md:"28px",lg:"28px"}, display:"flex" ,flexDirection:"row", justifyContent:"flex-end", gap:"20px", justifySelf:"end"}}>
                <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button data-testid="pwdChange"
                  onClick={()=> this.setState({passwordChangePopup:false})}
                  sx={{ textTransform:"none",fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                    Cancel
                  </Button>
                </Box>
                <Box data-testid="changePwd" sx={{ width: "183px", height: "44px", borderRadius: "8px", backgroundColor: "#44041C", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button onClick={this.changePasswordApi} sx={{textTransform:"none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                  Change password
                  </Button>
                </Box>
              </Box>

            
          </Box>
        </DialogContent>
      </Dialog>
      }
      {accountDeletePopup && 
      <Dialog
      sx={{
          padding: "32px",
          "& .MuiPaper-root": {
            borderRadius: "16px",
            maxWidth: "633px",
            width: "633px",
            height: "292px",
            overflow: "hidden",
            "@media (max-width: 768px)": {
              width: "90%", 
              height: "auto",
            },
          },
        }}
        open={accountDeletePopup}
      >
        <DialogContent>
          <Box sx={{ borderRadius: "16px", overflow: "hidden", display: "flex", justifyContent: "center",flexWrap: "wrap" }}>
            <Box sx={{
              width: "100%", alignItems: "center",
              "@media (max-width: 768px)": {
                padding: "16px",
              },
            }}
               >
                <Box>
                  <Typography sx={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "24px" }}>Are you sure you want to delete this account?</Typography>
                </Box>
              </Box>
              <Box marginTop="24px">
                <Typography sx={{fontFamily:"Poppins",fontWeight:400,color:"#64748B",fontSize:"18px"}}>This action can't be undone and all the data stored in this account won't be accessible anymore.</Typography>
              </Box>
              <Box width="100%" marginTop="32px">
                <Divider sx={{ marginTop:"32px",width: "100%", borderColor: "#CBD5E1", borderBottomWidth: 1 }} />
              </Box>
              <Box sx={{marginLeft:"45%",padding:{xs:"0px",sm:"15px",md:"28px",lg:"28px"}, display:"flex" ,flexDirection:"row", justifyContent:"flex-end", gap:"20px", justifySelf:"end"}}>
                <Box sx={{ width: "91px", height: "44px", borderRadius: "8px", border: "1px solid #44041C", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button 
                  data-testid="accountDeletePopup"
                  onClick={()=> this.setState({accountDeletePopup:false})}
                  sx={{ textTransform:"none",fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#44041C" }}>
                    Cancel
                  </Button>
                </Box>
                <Box  sx={{ width: "183px", height: "44px", borderRadius: "8px", backgroundColor: "#DC2626", border: "1px solid #DC2626", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button
                  data-testid="delete-account-button"
                  onClick={this.deleteAccountApi}
                  sx={{textTransform:"none", fontFamily: "Poppins", fontSize: "16px", fontWeight: 700, color: "#FFFFFF" }}>
                   Delete account
                  </Button>
                </Box>
              </Box>

            
          </Box>
        </DialogContent>
      </Dialog>
      }
      </Grid>
    </Grid> 
        </Paper>
      </Box>
        </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  resetLabelText: {
    fontFamily: "Poppins !important",
    fontWeight: "700",
    marginBottom:"4px",
    fontSize: 14,
    color: "#334155"
  },
  passwordValidBox:{
    display:"flex",
    flexDirection:"row"
  },
  passwordValidBoxTop:{
    display:"flex",
    flexDirection:"row",
    marginTop:"8px"
  },
    greenCheck:{
      marginRight: "3px",
      marginTop:"2px",
      color: "#34D399",
      width: "12px", 
      height: "12px", 
    },
  pwdText:{
    mb: 2,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":{
      borderRadius: "8px",
      border:"1px solid #CBD5E1"
    },
    "& .MuiInputBase-input":{
          fontFamily: "Poppins",
    }
  },
  pwdConditions:{
    fontSize: "12px",
    color: "#0F172A",
    fontFamily: "Poppins !important",
    fontWeight: "400",
},
}
// Customizable Area End
